import config from '../helpers/config';

if (!process.env.SERVER && config.get('support.helpshift.enabled')) {
	/* eslint-disable */
	(function() {
		var PLATFORM_ID = 'chatbooks_platform_20170831183046248-947cd4e888abd3c',
			DOMAIN = 'chatbooks';

		window.helpshiftConfig = {
			platformId: PLATFORM_ID,
			domain: DOMAIN,
			widgetOptions: {
				zIndex: 1
			}
		};
	})();

	!(function(t, e) {
		if ('function' != typeof window.Helpshift) {
			var n = function() {
				n.q.push(arguments);
			};

			n.q = [];
			window.Helpshift = n;
			var a = t.getElementsByTagName('script')[0];

			if (t.getElementById(e)) return;

			var i = t.createElement('script');
			i.async = !0;
			i.id = e;
			i.src = 'https://webchat.helpshift.com/webChat.js';

			var o = function() {
				window.Helpshift('init');
			};

			window.attachEvent ? i.attachEvent('onload', o) : i.addEventListener('load', o, !1),
				a.parentNode.insertBefore(i, a);
		} else window.Helpshift('update');
	})(document, 'hs-chat');
	/* eslint-enable */
}
