import deep from 'deep-get-set';
import { find } from 'lodash';
import { createSelector } from 'reselect';

import { selectAppStringByKeyMap } from './appString.selectors';
import { selectUserLongId, selectUserIdString, selectUser } from './user.selectors';
import { filterInNonImages, filterOutNonImages } from './groups.selectors.helpers';
import { selectSeriesPricing } from './pricing.selectors';
import { selectActiveMomentId, selectViewGroupId, selectViewSelectedMomentIds } from './view.selectors';
import { ENTITIES } from '../const/entities/entities';
import { REDUCERS } from '../const/reducers.const';
import { UPLOADER } from '../const/uploader.const';
import {
	isGroup10x10,
	isGroup6x6,
	isGroupSubscribed,
	isGroupTypeOfBook,
	isGroupTypeOfCustomBook,
	isGroupTypeOfSeriesBook,
} from '../helpers/groups/groups';
import { isTypeCustomBookOrLayflat } from '../helpers/groups/groupTypes';
import { maybeTransformPerson } from '../model/person.model';
import { getPromoLabel } from '../model/promo.model';

export const selectGroupsState = (state) => deep(state, `${REDUCERS.GROUPS}`);

export const selectGroupMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.GROUP}`) || {}
);

export const selectGetGroupByIdFn = createSelector(
	selectGroupMap,
	(groupMap) => (groupId) => deep(groupMap, `${groupId}`)
);

export const selectGroupIds = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.GROUP_IDS}`) || []
);

export const selectGroups = createSelector(
	selectGroupMap,
	selectGroupIds,
	(groupMap, groupIds) => {
		if (!groupMap || !groupIds) return null;

		return groupIds.map((id) => deep(groupMap, `${id}`));
	}
);

export const selectBookState = (state) => deep(state, `${REDUCERS.BOOK}`);

export const selectBookMap = createSelector(
	selectBookState,
	(bookState) => deep(bookState, `${ENTITIES.BOOK}`)
);

export const selectCoverBundleMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.COVER_BUNDLE}`)
);

export const selectCoverBundleIds = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.COVER_BUNDLE_IDS}`)
);

export const selectGroupCoverBundleMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.GROUP_COVER_BUNDLE}`)
);

export const selectGroupInviteLinkMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.GROUP_INVITE_CODE}`)
);

export const selectOrderMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.ORDER}`) || {}
);

export const selectGroupSeriesPriceMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.GROUP_SERIES_PRICE}`) || {}
);

export const selectGroupProductSubscriptionsMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.GROUP_SUBSCRIPTIONS_RESPONSE}`) || {}
);

export const selectGroupSubscriptionOrdersMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.GROUP_SUBSCRIPTION_ORDERS}`) || {}
);

export const selectGroupOrderCalculatedShippingOptionsResponseMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.GROUP_ORDER_CALCULATED_SHIPPING_OPTIONS_RESPONSE}`) || {}
);

export const selectGroupOrderCalculatedShippingOptionMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.GROUP_ORDER_CALCULATED_SHIPPING_OPTION}`) || {}
);

export const selectOrderShippingOptionMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.ORDER_SHIPPING_OPTION}`) || {}
);

export const selectOrderCalculatedShippingOptionMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.ORDER_CALCULATED_SHIPPING_OPTION}`) || {}
);

export const selectCropRect = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.CROP_RECT}`) || {}
);

export const selectMedia = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.MEDIA}`) || {}
);

export const selectCurrentGroupSeriesPrice = createSelector(
	selectGroupSeriesPriceMap,
	selectViewGroupId,
	(groupSeriesPriceMap, groupId) => deep(groupSeriesPriceMap, `${groupId}`)
);

export const selectCurrentGroupSeriesPriceSubtotal = createSelector(
	selectCurrentGroupSeriesPrice,
	(seriesPrice) => deep(seriesPrice, 'subtotal')
);

export const selectCurrentGroupSeriesVolumeCount = createSelector(
	selectCurrentGroupSeriesPrice,
	(price) => deep(price, 'summary.details.length') || 1
);

const selectCurrenctGroupSeriesPriceMarket = createSelector(
	selectCurrentGroupSeriesPrice,
	(price) => deep(price, 'market')
);

const selectCurrentGroupSeriesPricePromos = createSelector(
	selectCurrentGroupSeriesPrice,
	(price) => deep(price, 'promos') || []
);

export const selectCurrentGroupSeriesPromoLabels = createSelector(
	selectCurrentGroupSeriesPricePromos,
	(promos) => promos.map(getPromoLabel)
);

const selectCurrenctGroupSeriesPriceMarketCurrency = createSelector(
	selectCurrenctGroupSeriesPriceMarket,
	(market) => deep(market, 'currency')
);

export const selectCurrenctGroupSeriesPriceCurrencyCode = createSelector(
	selectCurrenctGroupSeriesPriceMarketCurrency,
	(currency) => deep(currency, 'code')
);

export const selectCurrentGroupSeriesPerVolumePrice = createSelector(
	selectCurrentGroupSeriesPriceSubtotal,
	selectCurrentGroupSeriesVolumeCount,
	(subtotal, volumeCount) => volumeCount ? subtotal / volumeCount : 10
);


export const selectCurrentGroupSubscriptionOrderIds = createSelector(
	selectViewGroupId,
	selectGroupSubscriptionOrdersMap,
	(groupId, groupSubscriptionOrders) => deep(groupSubscriptionOrders, `${groupId}.orders`) || []
);

export const selectCurrentGroupSubscriptionOrders = createSelector(
	selectCurrentGroupSubscriptionOrderIds,
	selectOrderMap,
	(orderIds, orderMap) => (orderIds || []).map((orderId) => orderMap[orderId])
);

export const selectCurrentGroupSubscriptionData = createSelector(
	selectViewGroupId,
	selectGroupProductSubscriptionsMap,
	(groupId, groupProductSubscriptionsMap) => deep(groupProductSubscriptionsMap, `${groupId}`) || {}
);

export const selectCurrentGroupSubscriptionsProductsIds = createSelector(
	selectCurrentGroupSubscriptionData,
	(groupSubscriptions) => deep(groupSubscriptions, `products`) || []
);

export const selectCurrentGroupOrderCalculatedShippingOptionsResponse = createSelector(
	selectViewGroupId,
	selectGroupOrderCalculatedShippingOptionsResponseMap,
	(groupId, groupOrderCalculatedShippingOptionsResponseMap) => deep(groupOrderCalculatedShippingOptionsResponseMap, `${groupId}`)
);

export const selectCurrentGroupOrderCalculatedShippingOptionIds = createSelector(
	selectCurrentGroupOrderCalculatedShippingOptionsResponse,
	(groupOrderCalculatedShippingOptionsResponse) => deep(groupOrderCalculatedShippingOptionsResponse, 'shippingOptions') || []
);

export const selectCurrentGroupOrderCalculatedShippingOptions = createSelector(
	selectCurrentGroupOrderCalculatedShippingOptionIds,
	selectGroupOrderCalculatedShippingOptionMap,
	(groupOrderCalculatedShippingOptionIds, groupOrderCalculatedShippingOptionMap) => {
		const cheapest = (a, b) => a.price - b.price;

		return groupOrderCalculatedShippingOptionIds
			.map((id) => deep(groupOrderCalculatedShippingOptionMap, `${id}`))
			.sort(cheapest);
	}
);

export const selectExcludedMomentsDateInfoMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.EXCLUDED_MOMENTS_DATE_INFO}`)
);

export const selectExcludedMomentsPageMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.EXCLUDED_MOMENTS_PAGE}`)
);

export const selectGroupExcludedMomentsPageMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.GROUP_EXCLUDED_MOMENTS_PAGE}`)
);

export const selectCoverBundles = createSelector(
	selectCoverBundleMap,
	selectCoverBundleIds,
	(coverBundles, coverBundleIds) => {
		if (!coverBundles || !coverBundleIds) return null;

		return coverBundleIds.map((id) => deep(coverBundles, `${id}`));
	}
);

export const selectMomentState = (state) => deep(state, `${REDUCERS.MOMENT}`);

export const selectMomentMap = createSelector(
	selectMomentState,
	(momentState) => deep(momentState, `${ENTITIES.MOMENT}`)
);

export const selectDataSourceMediaMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.DATA_SOURCE_MEDIA}`) || {}
);

export const selectDataSourceMediaPageMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.DATA_SOURCE_MEDIA_PAGE}`) || {}
);

export const selectPeopleMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.PERSON}`) || {}
);

export const selectCurrentGroup = createSelector(
	selectGroupMap,
	selectViewGroupId,
	(groups, viewGroupId) => deep(groups, `${viewGroupId}`)
);

export const selectCurrentGroupSeriesHardcoverPrice = createSelector(
	selectCurrentGroup,
	selectSeriesPricing,
	(currentGroup, seriesPricing) => isGroup6x6(currentGroup)
		? seriesPricing.hardcover6X6UpgradePrice
		: seriesPricing.hardcover8X8UpgradePrice
);

export const selectCurrentGroupPeople = createSelector(
	selectCurrentGroup,
	selectPeopleMap,
	(currentGroup, peopleMap) => (deep(currentGroup, 'people') || []).map((personId) => peopleMap[personId])
);

export const selectCurrentGroupOwner = createSelector(
	selectCurrentGroup,
	selectPeopleMap,
	(currentGroup, peopleMap) => peopleMap[currentGroup.owner]
);

export const selectCurrentGroupIsLoggedInAsOwner = createSelector(
	selectUserIdString,
	selectCurrentGroup,
	(userId, group) => group.owner && userId === group.owner
);

export const selectCurrentGroupInviteLink = createSelector(
	selectCurrentGroup,
	selectGroupInviteLinkMap,
	(currentGroup, inviteLinks) => deep(inviteLinks, `${currentGroup.id}.link`)
);

export const selectCurrentGroupContributors = createSelector(
	selectCurrentGroupOwner,
	selectUser,
	selectCurrentGroupPeople,
	(owner, user, people) => {
		const { contributors } = [owner, maybeTransformPerson(user), ...people]
			.reduce(({ contributors, visited }, contributor) => ({
				contributors: visited[contributor.id]
					? contributors
					: [...contributors, contributor],
				visited: {
					...visited,
					[contributor.id]: true
				},
			}), { contributors: [], visited: {} });

		return contributors;
	}
);

export const selectCurrentGroupBookCreationModelType = createSelector(
	selectCurrentGroup,
	(currentGroup) => deep(currentGroup, 'bookCreationModelType')
);

export const selectCurrentGroupIsHardcover = createSelector(
	selectCurrentGroup,
	(currentGroup) => deep(currentGroup, 'isHardcover')
);

export const selectCurrentGroupSkewyUrl = createSelector(
	selectCurrentGroup,
	(currentGroup) => deep(currentGroup, 'skewyUrl')
);

export const selectCurrentGroupTitle = createSelector(
	selectCurrentGroup,
	(currentGroup) => deep(currentGroup, 'title')
);

export const selectCurrentGroupSeriesBasePriceWithoutHardcoverPrice = createSelector(
	selectCurrentGroupSeriesPrice,
	selectCurrentGroupIsHardcover,
	selectCurrentGroupSeriesHardcoverPrice,
	(price, isHardcover, hardcoverPrice) => {
		const basePrice = deep(price, 'summary.details.0.price') || deep(price, 'summary.price');

		return isHardcover ? basePrice - hardcoverPrice : basePrice;
	}
);

export const selectCurrentGroupBookSize = createSelector(
	selectCurrentGroup,
	(currentGroup) => deep(currentGroup, 'bookSize')
);

export const selectCurrentGroupActiveCoverBundleId = createSelector(
	selectCurrentGroup,
	(currentGroup) => deep(currentGroup, 'activeCoverBundleId')
);

export const selectCurrentGroupActiveCoverBundle = createSelector(
	selectCurrentGroupActiveCoverBundleId,
	selectCoverBundleMap,
	(bundleId, coverBundles) => deep(coverBundles, `${bundleId}`)
);

export const selectCurrentGroupIs10x10 = createSelector(
	selectCurrentGroup,
	(group) => isGroup10x10(group)
);

export const selectCurrentGroupIsTypeOfBook = createSelector(
	selectCurrentGroup,
	(group) => isGroupTypeOfBook(group)
);

export const selectCurrentGroupIsTypeOfSeriesBook = createSelector(
	selectCurrentGroup,
	(group) => isGroupTypeOfSeriesBook(group)
);

export const selectCurrentGroupIsSubscribed = createSelector(
	selectCurrentGroup,
	(group) => isGroupSubscribed(group)
);

export const selectCurrentBookId = createSelector(
	selectViewGroupId,
	selectUserLongId,
	selectBookMap,
	(viewGroupId, authUserLongId, books) => {
		if (!viewGroupId || !authUserLongId || !books) return null;

		return `book:volume(1):group(${viewGroupId}):person(${authUserLongId})`;
	}
);

export const selectCurrentBook = createSelector(
	selectCurrentBookId,
	selectBookMap,
	(bookId, books) => {
		if (!bookId || !books) return null;

		return deep(books, `${bookId}`);
	}
);

export const selectCurrentBookCoverMediaId = createSelector(
	selectCurrentBook,
	(currentBook) => deep(currentBook, 'coverMediaId')
);

export const selectCurrentMoments = createSelector(
	selectCurrentBook,
	selectMomentMap,
	(currentBook, moments) => {
		if (!currentBook || !currentBook.momentIds || !moments) return [];

		return currentBook.momentIds
			.map((momentId) => moments[momentId]);
	}
);

export const selectViewSelectedMomentsMapped = createSelector(
	selectMomentMap,
	selectViewSelectedMomentIds,
	(momentMap, viewSelectedMomentIds) => viewSelectedMomentIds.map((momentId) => deep(momentMap, `${momentId}`))
);

export const selectCurrentMomentsLength = createSelector(
	selectCurrentMoments,
	(moments) => moments.length
);

export const selectCurrentMomentImages = createSelector(
	selectCurrentMoments,
	(currentMoments) => currentMoments.filter(filterOutNonImages)
);

export const selectCurrentMomentImagesLength = createSelector(
	selectCurrentMomentImages,
	(moments) => moments ? moments.length : 0
);

export const selectHasSelectedNonImageMoments = createSelector(
	selectViewSelectedMomentIds,
	selectMomentMap,
	(viewSelectedMomentIds, moments) => {
		if (!viewSelectedMomentIds || !moments) return false;

		const nonImageMoments = viewSelectedMomentIds
			.map((momentId) => moments[momentId])
			.filter(filterInNonImages);

		return nonImageMoments.length > 0;
	}
);

export const selectCollages = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, 'collage')
);

export const selectCollageMediaList = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.COLLAGE_MEDIA}`)
);

export const selectCoverTemplates = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.COVER_TEMPLATE}`)
);

export const selectActiveMoment = createSelector(
	selectActiveMomentId,
	selectMomentMap,
	(activeMomentId, momentMap) => {
		if (!activeMomentId || !momentMap) return null;

		return deep(momentMap, `${activeMomentId}`);
	}
);

export const selectActiveCollage = createSelector(
	selectActiveMomentId,
	selectCollages,
	(activeMomentId, collages) => {
		if (!activeMomentId || !collages) return null;

		return deep(collages, `${activeMomentId}`);
	}
);

export const selectActiveCollageMedia = createSelector(
	selectActiveCollage,
	selectCollageMediaList,
	selectMedia,
	(activeCollage, collageMediaList, media) => {
		if (!activeCollage || !collageMediaList) return null;

		return activeCollage.media.map((id) => {
			const collageMedia = deep(collageMediaList, `${id}`);
			const mediaId = deep(collageMedia, 'mediaId');
			const mediaObj = deep(media, `${mediaId}`);

			return {
				...collageMedia,
				...mediaObj
			};
		});
	}
);

export const selectActiveCollageMediaLength = createSelector(
	selectActiveCollageMedia,
	(activeCollageMedia) => {
		return (activeCollageMedia) ? activeCollageMedia.length : 0;
	}
);

export const selectActiveMedia = createSelector(
	selectActiveMoment,
	selectMedia,
	(moment, media) => {
		if (!moment || !moment.mediaId || !media) return null;

		return deep(media, `${moment.mediaId}`);
	}
);

export const selectIsPhotoCollageEnabled = createSelector(
	selectAppStringByKeyMap,
	(appStringByKey) => {
		const key = 'web.photo_collage_enabled';

		return (appStringByKey[key] || {}).Value === 'true';
	}
);

export const selectIsCollageAble = createSelector(
	selectCurrentGroupBookCreationModelType,
	selectHasSelectedNonImageMoments,
	selectCurrentMomentImagesLength,
	selectIsPhotoCollageEnabled,
	(
		groupModelType,
		hasSelectedNoneImageMoments,
		currentMomentImagesLength,
		isPhotoCollageEnabled
	) => {
		const noAvailableImages = currentMomentImagesLength === 0;
		const isCustomBookOrLayflat = isTypeCustomBookOrLayflat(groupModelType);

		return isCustomBookOrLayflat && isPhotoCollageEnabled && !hasSelectedNoneImageMoments && !noAvailableImages;
	}
);

export const selectIsCustomBookOrLayflat = createSelector(
	selectCurrentGroupBookCreationModelType,
	(groupModelType) => isTypeCustomBookOrLayflat(groupModelType)
);

export const selectBookCoverColors = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.BOOK_COVER_COLOR}`)
);

export const selectBookCoverColorIds = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.BOOK_COVER_COLOR_IDS}`) || []
);

export const selectBookCoverColorsMapped = createSelector(
	selectBookCoverColors,
	selectBookCoverColorIds,
	(bookCoverColors, bookCoverColorIds) => {
		if (!bookCoverColors || !bookCoverColorIds) return null;

		return bookCoverColorIds.map((id) => deep(bookCoverColors, `${id}`));
	}
);

export const selectBookCoverColorHardCoverDefault = createSelector(
	selectBookCoverColors,
	(colors) => find(colors, { isHardcoverDefault: true })
);

export const selectBookCoverColorSoftCoverDefault = createSelector(
	selectBookCoverColors,
	(colors) => find(colors, { isSoftcoverDefault: true })
);

export const selectBookLayoutOptions = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, 'bookLayoutOptions')
);

export const selectBookCoverLayoutVersion = createSelector(
	selectBookLayoutOptions,
	selectViewGroupId,
	(layoutOptions, groupId) => deep(layoutOptions, `${groupId}.coverLayoutVersion`)
);

export const selectBookCoverLayoutColorId = createSelector(
	selectBookLayoutOptions,
	selectViewGroupId,
	(layoutOptions, groupId) => deep(layoutOptions, `${groupId}.coverColorId`)
);

export const selectBookCoverLayoutActiveColor = createSelector(
	selectBookCoverLayoutColorId,
	selectCurrentGroupIsHardcover,
	selectBookCoverColorHardCoverDefault,
	selectBookCoverColorSoftCoverDefault,
	(bookCoverLayoutColorId, currentGroupIsHardcover, bookCoverColorHardCoverDefault, bookCoverColorSoftCoverDefault) => {
		if (bookCoverLayoutColorId) return bookCoverLayoutColorId;

		const defaultColor = (currentGroupIsHardcover) ? bookCoverColorHardCoverDefault : bookCoverColorSoftCoverDefault;

		return deep(defaultColor, 'id');
	}
);

export const selectCurrentCustomCoverTemplate = createSelector(
	selectCurrentGroup,
	selectCoverTemplates,
	(group, coverTemplates) => {
		const customCoverTemplateId = deep(group, 'customCoverTemplateId');

		return deep(coverTemplates, `${customCoverTemplateId}`);
	}
);

export const selectCurrentCoverTemplateIsDesignerCover = createSelector(
	selectCurrentCustomCoverTemplate,
	(coverTemplate) => coverTemplate && !coverTemplate.isStandardTemplate
);

export const selectCanShowCoverTitle = createSelector(
	selectCurrentGroup,
	selectCurrentCoverTemplateIsDesignerCover,
	selectCurrentGroupIs10x10,
	(group, isDesignerCover, is10x10) => {
		return isGroupTypeOfCustomBook(group) && !isDesignerCover && !is10x10;
	}
);

export const selectCollageLayouts = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, 'collageLayouts')
);

export const selectCollageLayoutIdsByBookSize = createSelector(
	selectGroupsState,
	selectCurrentGroupBookSize,
	(groupsState, bookSize) => deep(groupsState, `collageLayoutIdsByBookSize.${bookSize}`)
);

export const selectCollageLayoutsMapped = createSelector(
	selectCollageLayouts,
	selectCollageLayoutIdsByBookSize,
	(collageLayouts, collageLayoutIdsByBookSize) => {
		if (!collageLayouts || !collageLayoutIdsByBookSize) return [];

		return collageLayoutIdsByBookSize.layouts.map((id) => deep(collageLayouts, `${id}`));
	}
);

export const selectCoverCrop = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, 'coverCrop')
);

export const selectCurrentCoverCrop = createSelector(
	selectViewGroupId,
	selectCurrentBookCoverMediaId,
	selectCoverCrop,
	(groupId, mediaId, coverCrop) => {
		if (!groupId || !mediaId || !coverCrop) return null;

		const coverKey = `group(${groupId}):media(${mediaId})`;

		return deep(coverCrop, `${coverKey}`);
	}
);

export const selectCurrentCoverCropLowDpiWarning = createSelector(
	selectCurrentCoverCrop,
	(coverCrop) => deep(coverCrop, 'lowDpiWarning') || false
);

export const selectCurrentCoverLowDpiWarning = createSelector(
	selectCurrentCoverTemplateIsDesignerCover,
	selectCurrentCoverCropLowDpiWarning,
	(isDesigner, isCropLowDpi) => !isDesigner && isCropLowDpi
);

export const selectLocalMediaFileMap = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.LOCAL_MEDIA_FILE}`) || {}
);

export const selectCurrentMomentIsUploading = createSelector(
	selectActiveMomentId,
	selectLocalMediaFileMap,
	(activeMomentId, localFileMap) => {
		if (!activeMomentId || !localFileMap) return true;

		const allUploaded = Object
			.values(localFileMap)
			.filter((localFile) => localFile.momentId === String(activeMomentId))
			.reduce((allUploaded, localFile) => allUploaded && localFile.isUploaded, true);

		return !allUploaded;
	}
);

export const selectCurrentMomentUrl = createSelector(
	selectCurrentBook,
	selectCurrentGroupIsTypeOfBook,
	selectActiveMoment,
	selectCurrentMomentIsUploading,
	(book, isBook, moment, isUploading) => {
		if (!moment || isUploading) {
			return `${UPLOADER.BLANK_MOMENT_PREVIEW}`;
		} else if (isBook) {
			return moment.skewyPageUrl;
		} else {
			return moment.skewyPrintUrl;
		}
	}
);
