import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { usePrevious } from '@chtbks/hooks';

import HomePagePillButton from '../../components/Button/HomePagePillButton/HomePagePillButton';
import { IconCaretDown, IconX } from '../../components/Icons';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import withWindowSize from '../../components/WindowSize/withWindowSize';
import { DEVICE_WIDTH, mediaQueryBetween, mediaQueryStartingFrom, mediaQueryUpTo, SPACING } from '../../const';

const BREAKPOINT_STACKED_NARROW = DEVICE_WIDTH.MOBILE_L;
const BREAKPOINT_STACKED = DEVICE_WIDTH.LAPTOP;
const BREAKPOINT_SQUISHED = DEVICE_WIDTH.LAPTOP_L;
const HEIGHT = '754px';

const Accordion = styled(PageFlowContainer)`
	display: flex;
	overflow: hidden;

	${mediaQueryUpTo(BREAKPOINT_STACKED)} {
		flex-direction: column;
	}
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		height: ${HEIGHT};
		flex-direction: row;
	}
`;

const AccordionSection = styled.div`
	background-color: ${(props) => props.backgroundColor};
	display: flex;
	cursor: ${(props) => !props.isOpen && 'pointer'};

	${mediaQueryUpTo(BREAKPOINT_STACKED)} {
		flex-direction: column;
	}
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		flex-direction: row;
		height: ${HEIGHT};
	}
`;

const SpineDiv = styled.div`
	align-content: center;
	align-self: center;
	display: grid;
	justify-self: center;

	${mediaQueryUpTo(BREAKPOINT_STACKED)} {
		align-content: center;
		display: ${(props) => props.isOpen ? 'none' : 'grid'};
		height: 72px;
	}
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		height: ${HEIGHT};
		width: 131.75px;
	}
	${mediaQueryBetween(BREAKPOINT_STACKED, BREAKPOINT_SQUISHED)} {
		max-width: 115px;
	}
`;

const SPINE_TITLE_GRID_DIV_WIDTH = '230px';
const SPINE_TITLE_DIV_WIDTH = '210px';
const SpineInnerDiv = styled.div`
	display: grid;
	width: ${SPINE_TITLE_GRID_DIV_WIDTH};
	grid-template-columns: 1fr ${SPINE_TITLE_DIV_WIDTH} 1fr;
	align-items: center;

	${mediaQueryBetween(BREAKPOINT_STACKED, BREAKPOINT_SQUISHED)} {
		transform: translate(-55px, 0) rotate(-90deg);
	}
	${mediaQueryStartingFrom(BREAKPOINT_SQUISHED)} {
		transform: translate(-50px, 0) rotate(-90deg);
	}
`;

const colored = css`
	color: ${(props) => props.color};
`;

const ProductSpineTitleDiv = styled.div`
	${colored};
	font-family: ${(props) => props.isHovered && !props.isOpen ? 'Circular-Pro-Bold' : 'Circular-Pro-Medium'};
	text-align: center;
	line-height: 19px;

	${mediaQueryUpTo(BREAKPOINT_STACKED_NARROW)} {
		font-size: ${(props) => props.isHovered && !props.isOpen ? '15px' : '14px'};
	}
	${mediaQueryBetween(BREAKPOINT_STACKED_NARROW, BREAKPOINT_STACKED)} {
		font-size: ${(props) => props.isHovered && !props.isOpen ? '15px' : '14px'};
	}
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		font-size: ${(props) => props.isHovered && !props.isOpen ? '17px' : '16.6px'};
	}
`;

const OpenSectionCaret = styled(IconCaretDown)`
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		display: none;
	}
`;

const ProductContentsDiv = styled.div`
	position: relative;
	display: ${(props) => props.isOpen ? 'grid' : 'none'};

	${mediaQueryUpTo(BREAKPOINT_STACKED)} {
		grid-template-columns: 1fr;
		grid-template-areas:
			"image"
			"details";
	}
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		margin-left: ${SPACING.SMALL};
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"details image";
	}
	${mediaQueryBetween(BREAKPOINT_STACKED, BREAKPOINT_SQUISHED)} {
		grid-template-columns: 1fr 2fr;
	}
`;

const ProductDetailsDiv = styled.div`
	grid-area: details;
	margin-top: ${SPACING.SMALL};

	${mediaQueryUpTo(BREAKPOINT_STACKED)} {
		align-items: center;
		display: flex;
		flex-direction: column;
		text-align: center;
		padding-left: 6%;
		padding-right: 6%;
	}
`;

const CloseButton = styled.button`
	cursor: pointer;
	position: absolute;
	right: ${SPACING.BIG};
	top: ${SPACING.BIG};
`;

const HighlightDiv = styled.div`
	${colored};
	font-family: Circular-Pro-Medium;

	${mediaQueryUpTo(BREAKPOINT_STACKED)} {
		display: ${(props) => props.isFirst ? 'block' : 'none'};
		font-size: 14px;
		line-height: 10px;
		position: absolute;
		text-align: center;
		top: ${SPACING.BIGGEST};
		width: 100%;
	}
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		font-size: 16.6018px;
		line-height: 19px;
		margin-top: 116px;
	}
`;
const TitleDiv = styled.div`
	${colored};
	font-family: Circular-Pro-Bold;

	${mediaQueryUpTo(BREAKPOINT_STACKED_NARROW)} {
		font-size: 29px;
		letter-spacing: 0.0125em;
		line-height: 33px;
	}
	${mediaQueryBetween(BREAKPOINT_STACKED_NARROW, BREAKPOINT_STACKED)} {
		font-size: 33px;
		letter-spacing: 0.0125em;
		line-height: 36px;
	}
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		font-size: 55px;
		line-height: 66px;
		margin-top: 32px;
		max-width: 330px;
	}
	${mediaQueryBetween(BREAKPOINT_STACKED, BREAKPOINT_SQUISHED)} {
		font-size: 40px;
		line-height: 60px;
		max-width: 300px;
	}
`;
const PriceDiv = styled.p`
	${colored};
	font-family: Circular-Pro-Medium;
	font-size: 15px;
	margin-top: ${SPACING.SMALL};

	${mediaQueryUpTo(BREAKPOINT_STACKED_NARROW)} {
		font-size: 12px;
		line-height: 14px;
	}
	${mediaQueryBetween(BREAKPOINT_STACKED_NARROW, BREAKPOINT_STACKED)} {
		font-size: 13px;
		line-height: 14px;
	}
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		font-size: 16.6018px;
		line-height: 19px;
	}
	${mediaQueryBetween(BREAKPOINT_STACKED, BREAKPOINT_SQUISHED)} {
		font-size: 14px;
		line-height: 16.5px;
	}
`;
const DescriptionDiv = styled.div`
	${colored};
	font-family: Inter;
	font-size: 15px;
	font-style: normal;

	${mediaQueryUpTo(BREAKPOINT_STACKED_NARROW)} {
		font-size: 12px;
		font-weight: 500;
		line-height: 18px;
		max-width: 283px;
	}
	${mediaQueryBetween(BREAKPOINT_STACKED_NARROW, BREAKPOINT_STACKED)} {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		max-width: 300px;
	}
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		font-size: 16px;
		font-weight: normal;
		line-height: 26px;
		max-width: 268px;
	}
	${mediaQueryBetween(BREAKPOINT_STACKED, BREAKPOINT_SQUISHED)} {
		font-size: 14px;
		max-width: 240px;
	}
`;
const Cta = styled(HomePagePillButton)`
	${mediaQueryUpTo(BREAKPOINT_STACKED)} {
		margin-bottom: ${SPACING.BIGGEST};
		margin-top: ${SPACING.BIGGEST};
		max-width: 300px;
	}
	${mediaQueryStartingFrom(BREAKPOINT_STACKED)} {
		margin-top: ${SPACING.BIGGEST};
	}
`;
const ProductImage = styled.img`
	grid-area: image;
	align-self: center;
	justify-self: center;
	object-fit: contain;

	${mediaQueryUpTo(BREAKPOINT_STACKED)} {
		max-width: 340px;
		margin-top: ${SPACING.BIG};
	}
	${mediaQueryStartingFrom(BREAKPOINT_SQUISHED)} {
		max-width: 500px;
	}
	${mediaQueryBetween(BREAKPOINT_STACKED, BREAKPOINT_SQUISHED)} {
		max-width: 400px;
	}
`;

const replaceNewLineWithBreakTag = (str) => str.replace('\\n', '<br />');
const replaceNewLineWithSpace = (str) => str.replace('\\n', ' ');

const ProductAccordion = ({
	linkCallback,
	products,
	windowSize,
}) => {
	const isMobile = windowSize.width < DEVICE_WIDTH.TABLET;
	const isStacked = windowSize.width < BREAKPOINT_STACKED;
	const prevIsStacked = usePrevious(isStacked);

	const [activeIndex, setActiveIndex] = useState(0);
	const [hoveredIndex, setHoveredIndex] = useState(null);

	useEffect(() => {
		if (isStacked !== prevIsStacked && !isStacked && !activeIndex) setActiveIndex(0);
	}, [activeIndex, isStacked, prevIsStacked]);

	return (
		<Accordion isInset={false}>
			{products.map((product, index) => {
				const isOpen = activeIndex === index;
				const isHovered = hoveredIndex === index;

				return (
					<AccordionSection
						key={product.productName}
						isOpen={isOpen}
						backgroundColor={product.color}
						onClick={() => setActiveIndex(index)}
						onMouseEnter={() => setHoveredIndex(index)}
						onMouseLeave={() => setHoveredIndex(null)}
					>
						<SpineDiv
							isOpen={isOpen}>
							<SpineInnerDiv>
								<div>{' '}</div>
								<ProductSpineTitleDiv
									isOpen={isOpen}
									isHovered={isHovered}
									color={product.productNameVerticalColor}>
									{replaceNewLineWithSpace(product.productName)}
								</ProductSpineTitleDiv>
								<OpenSectionCaret
									color={product.productNameVerticalColor}
								/>
							</SpineInnerDiv>
						</SpineDiv>
						<ProductContentsDiv
							isOpen={isOpen}>
							{isStacked && (
								<CloseButton
									onClick={(e) => {
										setActiveIndex(null);
										e.stopPropagation();
									}}>
									<IconX size={15} color={product.productNameHorizontalColor} />
								</CloseButton>
							)}
							<ProductDetailsDiv>
								<HighlightDiv
									color={product.highlightTextColor}
									isFirst={index === 0}>
									{product.highlightText}
								</HighlightDiv>
								<TitleDiv
									color={product.productNameHorizontalColor}
									dangerouslySetInnerHTML={{ __html: replaceNewLineWithBreakTag(product.productName) }}
								/>
								<PriceDiv
									color={product.productPriceTextColor}>
									{product.productPriceText}
								</PriceDiv>
								<DescriptionDiv
									color={product.productDescriptionColor}>
									{product.productDescription}
								</DescriptionDiv>
								<Cta
									color={product.ctaTextColor}
									backgroundColor={product.ctaButtonColor}
									hoverBackgroundColor={product.ctaButtonHoverColor}
									onClick={() => linkCallback(isMobile ? product.ctaUrlMobile : product.ctaUrlDesktop)}>
									{isMobile ? product.ctaTextMobile : product.ctaTextDesktop}
								</Cta>
							</ProductDetailsDiv>
							<ProductImage
								src={product.imageUrl}
								alt={product.imageAlt}
							/>
						</ProductContentsDiv>
					</AccordionSection>
				)
			})}
		</Accordion>
	);
};

ProductAccordion.displayName = 'ProductAccordion';

ProductAccordion.propTypes = {
	linkCallback: PropTypes.func,
	products: PropTypes.arrayOf(PropTypes.shape({
		color: PropTypes.string,
		imageUrl: PropTypes.string,
		imageAlt: PropTypes.string,
		ctaButtonColor: PropTypes.string,
		ctaButtonHoverColor: PropTypes.string,
		ctaTextColor: PropTypes.string,
		ctaTextDesktop: PropTypes.string,
		ctaTextMobile: PropTypes.string,
		ctaUrlDesktop: PropTypes.string,
		ctaUrlMobile: PropTypes.string,
		highlightText: PropTypes.string,
		highlightTextColor: PropTypes.string,
		productDescription: PropTypes.string,
		productDescriptionColor: PropTypes.string,
		productName: PropTypes.string,
		productNameHorizontalColor: PropTypes.string,
		productNameVerticalColor: PropTypes.string,
		productPriceText: PropTypes.string,
		productPriceTextColor: PropTypes.string,
	})),
	windowSize: PropTypes.object,
};

export default withWindowSize(ProductAccordion);

