import * as FacebookService from './facebook';

const _getEmailAndName = () => {
	return FacebookService.getFacebookSdk()
		.then((FB) => new Promise((resolve, reject) => {
			FB.api(
				'/me',
				{ fields: 'name,email' },
				(response) => {
					if (!response || response.error) {
						console.error('Failed to retrieve Facebook username and email', response);
						reject();
					} else {
						resolve(response);
					}
				},
			);
		}));
};

export const authenticate = () => {
	return FacebookService.authenticate({
		scope: 'email,public_profile,user_messenger_contact',
		messenger_page_id: 434468146684211,
	})
		.then((authResult) => {
			return (!authResult.accessToken)
				? Promise.reject()
				: _getEmailAndName()
					.then((userData) => ({
						accessToken: authResult.accessToken,
						email: userData.email,
						name: userData.name,
					}));
		});
};
