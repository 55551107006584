import debug from 'debug';

export const perfLogger = debug('chtbks:perf');

export const appLogger = debug('chtbks:app');

// eslint-disable-next-line
export const perfHooks = (process.env.SERVER && perfLogger.enabled && eval('require("perf_hooks")')) || {
	performance: { clearMarks: () => {}, mark: () => {}, measure: () => {} }
};
