export default {
	AddPhotos_Back: {
		name: 'AddPhotos_Back',
		screen: 'AddPhotos',
		action: 'Back'
	},
	AddPhotos_Exit: {
		name: 'AddPhotos_Exit',
		screen: 'AddPhotos',
		action: 'Exit'
	},
	addPhotosChooseSource: {
		name: 'AddPhotos_ChooseSource',
		screen: 'AddPhotos',
		action: 'ChooseSource'
	},
	AddPhotos_Cards: 'AddPhotos_Cards',
	AddSource_Back: {
		name: 'AddSource_Back',
		screen: 'AddSource',
		action: 'Back'
	},
	addSourceChooseSource: {
		name: 'AddSource_ChooseSource',
		screen: 'AddSource',
		action: 'ChooseSource'
	},
	AddSource_Exit: {
		name: 'AddSource_Exit',
		screen: 'AddSource',
		action: 'Exit'
	},
	ApproveLayout_Exit: {
		name: 'ApproveLayout_Exit',
		screen: 'ApproveLayout',
		action: 'Exit'
	},
	AppLaunch: 'AppLaunch',
	BlogLaunch: 'BlogLaunch',
	BookInfo_Back: {
		name: 'BookInfo_Back',
		screen: 'BookInfo',
		action: 'Back'
	},
	BookInfo_Order: {
		name: 'BookInfo_Order',
		screen: 'BookInfo',
		action: 'Order'
	},
	CardsMain_Selection: {
		name: 'CardsMain_Selection',
		screen: 'CardsMain',
		action: 'Selection'
	},
	CardsDetail_Next: {
		name: 'CardsDetail_Next',
		screen: 'CardsDetail',
		action: 'Next'
	},
	CardsFront_ChangeTemplate: {
		name: 'CardsFront_ChangeTemplate',
		screen: 'CardsFront',
		action: 'ChangeTemplate'
	},
	CardsFront_Next: {
		name: 'CardsFront_Next',
		screen: 'CardsFront',
		action: 'Next'
	},
	CardsBack_Next: {
		name: 'CardsBack_Next',
		screen: 'CardsBack',
		action: 'Next'
	},
	CardsCustomDetail_DownloadTemplate: {
		name: 'CardsCustomDetail_DownloadTemplate',
		screen: 'CardsCustomDetail',
		action: 'DownloadTemplate'
	},
	Envelope_Next: {
		name: 'Envelope_Next',
		screen: 'Envelope',
		action: 'Next'
	},
	CardPreview: 'CardPreview',
	CardsSave_Prompt: {
		name: 'CardsSave_Prompt',
		screen: 'CardsSave',
		action: 'Prompt'
	},
	CardsSave_Selection: {
		name: 'CardsSave_Selection',
		screen: 'CardsSave',
		action: 'Selection'
	},
	CoverURL: {
		name: 'CoverURL',
	},
	EditCards_EditOption: {
		name: 'EditCards_EditOption',
		screen: 'EditCard',
		action: 'EditOption'
	},
	createCustomPrintsClicked: 'Create_CustomPrints_Clicked',
	createGiftBookClicked: 'Create_GiftBook_Clicked',
	createMainBookSeries: {
		name: 'CreateMain_BookSeries',
		screen: 'CreateMain',
		action: 'BookSeries'
	},
	createMainCustomBooks: {
		name: 'CreateMain_CustomBooks',
		screen: 'CreateMain',
		action: 'CustomBooks'
	},
	CreateMain_LearnMore: {
		name: 'CreateMain_LearnMore',
		screen: 'CreateMain',
		action: 'LearnMore'
	},
	createMainMore: {
		name: 'CreateMain_More',
		screen: 'CreateMain',
		action: 'More'
	},
	CreateMain_RecommededProduct: {
		name: 'CreateMain_RecommededProduct',
		screen: 'CreateMain',
		action: 'RecommededProduct'
	},
	createLayflatBookSeries: {
		name: 'CreateMain_LayflatBook',
		screen: 'CreateMain',
		action: 'LayflatBook'
	},
	createCustom6x6: {
		name: 'CreateCustom_6x6',
		screen: 'CreateCustom',
		action: '6x6'
	},
	createCustom8x8: {
		name: 'CreateCustom_8x8',
		screen: 'CreateCustom',
		action: '8x8'
	},
	createMoreChatbooksCollections: {
		name: 'CreateMore_ChatbooksCollections',
		screen: 'CreateMore',
		action: 'ChatbooksCollections'
	},
	createMoreGiftCard: {
		name: 'CreateMore_GiftCard',
		screen: 'CreateMore',
		action: 'GiftCard'
	},
	createMoreEnterCode: {
		name: 'CreateMore_EnterCode',
		screen: 'CreateMore',
		action: 'EnterCode'
	},
	createdChatgroup: {
		name: 'CreatedChatgroup',
		screen: '',
		action: ''
	},
	CustomEditBook_Add: {
		name: 'CustomEditBook_Add',
		event_type: 'CustomEditBook_Add',
		screen: 'CustomEditBook',
		action: 'Add',
	},
	CustomEditBook_AddToCart: {
		name: 'CustomEditBook_AddToCart',
		event_type: 'CustomEditBook_AddToCart',
		screen: 'CustomEditBook',
		action: 'AddToCart'
	},
	CustomEditBook_BookOptions: {
		name: 'CustomEditBook_BookOptions',
		event_type: 'CustomEditBook_BookOptions',
		screen: 'CustomEditBook',
		action: 'BookOptions',
	},
	CustomEditBook_EditCover: {
		name: 'CustomEditBook_EditCover',
		event_type: 'CustomEditBook_EditCover',
		screen: 'CustomEditBook',
		action: 'EditCover',
	},
	CustomEditBook_EditTitle: {
		name: 'CustomEditBook_EditTitle',
		event_type: 'CustomEditBook_EditTitle',
		screen: 'CustomEditBook',
		action: 'EditTitle',
	},
	CustomEditBook_FirstLoad: {
		name: 'CustomEditBook_FirstLoad',
		event_type: 'CustomEditBook_FirstLoad',
		screen: 'CustomEditBook',
		action: 'FirstLoad',
	},
	CustomEditBook_MultiSelect: {
		name: 'CustomEditBook_MultiSelect',
		event_type: 'CustomEditBook_MultiSelect',
		screen: 'CustomEditBook',
		action: 'MultiSelect',
	},
	CustomEditBook_Preview: {
		name: 'CustomEditBook_Preview',
		event_type: 'CustomEditBook_Preview',
		screen: 'CustomEditBook',
		action: 'Preview',
	},
	CustomEditBook_Rearrange: {
		name: 'CustomEditBook_Rearrange',
		event_type: 'CustomEditBook_Rearrange',
		screen: 'CustomEditBook',
		action: 'Rearrange',
	},
	CustomEditBook_ViewInCart: {
		name: 'CustomEditBook_ViewInCart',
		event_type: 'CustomEditBook_ViewInCart',
		screen: 'CustomEditBook',
		action: 'ViewInCart',
	},
	CustomEditBook_ViewPage: {
		name: 'CustomEditBook_ViewPage',
		event_type: 'CustomEditBook_ViewPage',
		screen: 'CustomEditBook',
		action: 'ViewPage',
	},
	EditBook_AddPhotos: {
		name: 'EditBook_AddPhotos',
		event_type: 'EditBook_AddPhotos',
		screen: 'EditBook',
		action: 'AddPhotos',
	},
	EditBook_EditCover: {
		name: 'EditBook_EditCover',
		event_type: 'EditBook_EditCover',
		screen: 'EditBook',
		action: 'EditCover',
	},
	EditBook_MultiSelect: {
		name: 'EditBook_MultiSelect',
		event_type: 'EditBook_MultiSelect',
		screen: 'EditBook',
		action: 'MultiSelect',
	},
	EditBook_Preview: {
		name: 'EditBook_Preview',
		event_type: 'EditBook_Preview',
		screen: 'EditBook',
		action: 'Preview',
	},
	EditBook_Rearrange: {
		name: 'EditBook_Rearrange',
		event_type: 'EditBook_Rearrange',
		screen: 'EditBook',
		action: 'Rearrange',
	},
	EditBook_ViewPage: {
		name: 'EditBook_ViewPage',
		event_type: 'EditBook_ViewPage',
		screen: 'EditBook',
		action: 'ViewPage',
	},
	EditPage_ChangePageLayout: {
		name: 'EditPage_ChangePageLayout',
		event_type: 'EditPage_ChangePageLayout',
		screen: 'EditPage',
		action: 'ChangePageLayout',
	},
	EditPage_Crop: {
		name: 'EditPage_Crop',
		event_type: 'EditPage_Crop',
		screen: 'EditPage',
		action: 'Crop',
	},
	EditPage_EditCaption: {
		name: 'EditPage_EditCaption',
		event_type: 'EditPage_EditCaption',
		screen: 'EditPage',
		action: 'EditCaption',
	},
	EditPage_Options: {
		name: 'EditPage_Options',
		event_type: 'EditPage_Options',
		screen: 'EditPage',
		action: 'Options',
	},
	FirstAppLaunch: 'FirstAppLaunch',
	FirstBlogLaunch: 'FirstBlogLaunch',
	FirstMarketingLaunch: 'FirstMarketingLaunch',
	firstPurchase: {
		name: 'First Purchase',
		screen: '',
		action: ''
	},
	GiftCard_Load: {
		name: 'GiftCard_Load',
		screen: 'GiftCard',
		action: 'Load',
	},
	GiftCard_Amount: {
		name: 'GiftCard_Amount',
		screen: 'GiftCard',
		action: 'ChooseAmount',
	},
	GiftCard_Delivery: {
		name: 'GiftCard_Delivery',
		screen: 'GiftCard',
		action: 'ChooseDelivery',
	},
	GiftCard_AddToCart: {
		name: 'GiftCard_AddToCart',
		screen: 'GiftCard',
		action: 'AddToCart',
	},
	homeChooseChatGroup: {
		name: 'Home_ChooseChatGroup',
		screen: 'Home',
		action: 'ChooseChatGroup'
	},
	homeCreateNew: {
		name: 'Home_CreateNew',
		screen: 'Home',
		action: 'CreateNew'
	},
	IGSeriesLanding_Continue: {
		name: 'IGSeriesLanding_Continue',
		screen: 'IGSeriesLanding',
		action: 'Continue'
	},
	IGSeriesLanding_Expand: {
		name: 'IGSeriesLanding_Expand',
		screen: 'IGSeriesLanding',
		action: 'Expand'
	},
	IGSeriesLanding_Load: {
		name: 'IGSeriesLanding_Load',
		screen: 'IGSeriesLanding',
		action: 'Load'
	},
	IGSeriesPreview_Continue: {
		name: 'IGSeriesPreview_Continue',
		screen: 'IGSeriesPreview',
		action: 'Continue'
	},
	IGSeriesPreview_Load: {
		name: 'IGSeriesPreview_Load',
		screen: 'IGSeriesPreview',
		action: 'Load'
	},
	MarketingLaunch: 'MarketingLaunch',
	MarketingHome_Download: {
		screen: 'MarketingHome',
		action: 'Download'
	},
	MarketingHome_GetStarted: {
		screen: 'MarketingHome',
		action: 'GetStarted'
	},
	MarketingHome_SelectProduct: {
		screen: 'MarketingHome',
		action: 'SelectProduct'
	},
	MarketingHome_Testimonial: {
		screen: 'MarketingHome',
		action: 'Testimonial'
	},
	MarketingSite_FooterSelect: {
		screen: 'MarketingSite',
		action: 'FooterSelect'
	},
	MarketingSite_HeaderAccountSelect: {
		screen: 'MarketingSite',
		action: 'HeaderAccountSelect'
	},
	MarketingSite_HeaderHelpSelect: {
		screen: 'MarketingSite',
		action: 'HeaderHelpSelect'
	},
	MarketingSite_HeaderProductsSelect: {
		screen: 'MarketingSite',
		action: 'HeaderProductsSelect'
	},
	MarketingSite_HeaderSelect: {
		screen: 'MarketingSite',
		action: 'HeaderSelect'
	},
	MobileOverlay_ContinueInApp: {
		name: 'MobileOverlay_ContinueInApp',
		screen: 'MobileOverlay',
		action: 'ContinueInApp'
	},
	MobileOverlay_ContinueToWebSite: {
		name: 'MobileOverlay_ContinueToWebSite',
		screen: 'MobileOverlay',
		action: 'ContinueToWebSite'
	},
	orderCompletedPage: {
		name: 'OrderConfirmation_Load',
		screen: 'OrderConfirmation',
		action: 'Load'
	},
	OrderConfirmation_Survey: {
		name: 'OrderConfirmation_Survey',
		screen: 'OrderConfirmation',
		action: 'Survey'
	},
	OrderConfirmation_ViewGiftCard: {
		name: 'OrderConfirmation_ViewGiftCard',
		screen: 'OrderConfirmation',
		action: 'ViewGiftCard'
	},
	orderCustomPrintsClicked: 'Order_CustomPrints_Clicked',
	orderSeriesPrintsClicked: 'Order_SeriesPrints_Clicked',
	OrderWizard_AddPaymentType: {
		name: 'OrderWizard_AddPaymentType',
		screen: 'OrderWizard',
		action: 'AddPaymentType'
	},
	OrderWizard_ChooseAddress: {
		name: 'OrderWizard_ChooseAddress',
		screen: 'OrderWizard',
		action: 'ChooseAddress'
	},
	OrderWizard_EnterCode: {
		name: 'OrderWizard_EnterCode',
		screen: 'OrderWizard',
		action: 'EnterCode'
	},
	OrderWizard_Exit: {
		name: 'OrderWizard_Exit',
		screen: 'OrderWizard',
		action: 'Exit'
	},
	OrderWizard_NewAddress: {
		name: 'OrderWizard_NewAddress',
		screen: 'OrderWizard',
		action: 'NewAddress'
	},
	OrderWizard_Preview: {
		name: 'OrderWizard_Preview',
		screen: 'OrderWizard',
		action: 'Preview'
	},
	OrderWizard_UpgradeHardCover: {
		name: 'OrderWizard_UpgradeHardCover',
		screen: 'OrderWizard',
		action: 'UpgradeHardCover'
	},
	OrderWizard_ChooseCatchupSpeed: {
		name: 'OrderWizard_ChooseCatchupSpeed',
		screen: 'OrderWizard',
		action: 'ChooseCatchupSpeed'
	},
	OrderWizard_ChoosePaymentType: {
		name: 'OrderWizard_ChoosePaymentType',
		screen: 'OrderWizard',
		action: 'ChoosePaymentType'
	},
	OrderWizard_ChooseShippingSpeed: {
		name: 'OrderWizard_ChooseShippingSpeed',
		screen: 'OrderWizard',
		action: 'ChooseShippingSpeed'
	},
	OrderWizard_Back: {
		name: 'OrderWizard_Back',
		screen: 'OrderWizard',
		action: 'Back'
	},
	OrderWizard_Purchase: {
		name: 'OrderWizard_Purchase',
		screen: 'OrderWizard',
		action: 'Purchase'
	},
	PhotoBookOptions_ChoosePhotoSource: {
		name: 'PhotoBookOptions_ChoosePhotoSource',
		screen: 'PhotoBookOptions',
		action: 'ChoosePhotoSource'
	},
	PostPurchaseSurvey_Load: {
		name: 'PostPurchaseSurvey_Load',
		screen: 'PostPurchaseSurvey',
		action: 'Load'
	},
	PostPurchaseSurvey_Skip: {
		name: 'PostPurchaseSurvey_Skip',
		screen: 'PostPurchaseSurvey',
		action: 'Skip'
	},
	PostPurchaseSurvey_Submit: {
		name: 'PostPurchaseSurvey_Submit',
		screen: 'PostPurchaseSurvey',
		action: 'Submit'
	},
	printsSetQuantityClicked: 'Prints_SetQuantity_Clicked',
	ProductExplainer_Back: {
		name: 'ProductExplainer_Back',
		screen: 'ProductExplainer',
		action: 'Back'
	},
	productExplainerContinue: {
		name: 'ProductExplainer_Continue',
		screen: 'ProductExplainer',
		action: 'Continue'
	},
	purchase: {
		name: 'Purchase',
		screen: '',
		action: ''
	},
	QuestionnairePage1_Next: {
		name: 'QuestionnairePage1_Next',
		screen: 'QuestionnairePage1',
		action: 'Next'
	},
	QuestionnairePage2_Next: {
		name: 'QuestionnairePage2_Next',
		screen: 'QuestionnairePage2',
		action: 'Next'
	},
	QuestionnairePage3_Next: {
		name: 'QuestionnairePage3_Next',
		screen: 'QuestionnairePage3',
		action: 'Next'
	},
	QuickFlowLanding_ChooseSource: {
		name: 'QuickFlowLanding_ChooseSource',
		screen: 'QuickFlowLanding',
		action: 'ChooseSource'
	},
	QuickFlowLanding_Load: {
		name: 'QuickFlowLanding_Load',
		screen: 'QuickFlowLanding',
		action: 'Load'
	},
	QuickReauthLanding_Action: {
		name: 'QuickReauthLanding_Action',
		screen: 'QuickReauthLanding',
		action: 'Action'
	},
	QuickReauthLanding_Load: {
		name: 'QuickReauthLanding_Load',
		screen: 'QuickReauthLanding',
		action: 'Load'
	},
	QuickReauthStatus_Action: {
		name: 'QuickReauthStatus_Action',
		screen: 'QuickReauthStatus',
		action: 'Action'
	},
	QuickReauthStatus_Load: {
		name: 'QuickReauthStatus_Load',
		screen: 'QuickReauthStatus',
		action: 'Load'
	},
	QuickSeriesLanding_Continue: {
		name: 'QuickSeriesLanding_Continue',
		screen: 'QuickSeriesLanding',
		action: 'Continue'
	},
	QuickSeriesLanding_Load: {
		name: 'QuickSeriesLanding_Load',
		screen: 'QuickSeriesLanding',
		action: 'Load'
	},
	referralInviteEmailClicked: 'ReferralInvite_Email_Clicked',
	referralsPage: 'Referrals_Page',
	selectCustomCoverOpened: 'SelectCustomCover_Opened',
	selectCustomCoverSelected: 'SelectCustomCover_Selected',
	SeriesInfo_EditBook: {
		name: 'SeriesInfo_EditBook',
		event_type: 'SeriesInfo_EditBook',
		screen: 'SeriesInfo',
		action: 'EditBook',
	},
	SeriesInfo_EditTitle: {
		name: 'SeriesInfo_EditTitle',
		event_type: 'SeriesInfo_EditTitle',
		screen: 'SeriesInfo',
		action: 'EditTitle',
	},
	SeriesInfo_SeriesOptions: {
		name: 'SeriesInfo_SeriesOptions',
		event_type: 'SeriesInfo_SeriesOptions',
		screen: 'SeriesInfo',
		action: 'SeriesOptions',
	},
	SeriesInfo_Subscribe: {
		name: 'SeriesInfo_Subscribe',
		event_type: 'SeriesInfo_Subscribe',
		screen: 'SeriesInfo',
		action: 'Subscribe'
	},
	shippingUpgradeChangeShippingMethod: 'ShippingUpgrade_ChangeShippingMethod',
	shippingUpgradePage: 'ShippingUpgrade_Page',
	SubscriptionOptions_ChoosePhotoSource: {
		name: 'SubscriptionOptions_ChoosePhotoSource',
		screen: 'SubscriptionOptions',
		action: 'ChoosePhotoSource'
	},
};
