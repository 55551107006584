import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { hermes, noop } from '@chtbks/helpers';

import { defaultCookieOptions } from './defaultCookieOptions';

export function useForceUpdateUseCookiesHack() {
	// eslint-disable-next-line no-unused-vars
	const [_, setCookie] = useCookies();

	useEffect(() => {
		const cookieUpdatedCallback = () => {
			setCookie('__chtbks_touch', new Date().toString(), defaultCookieOptions());
		}

		hermes.listen(hermes.EVENTS.COOKIE_UPDATED, cookieUpdatedCallback, noop);

		return () => hermes.squelch(hermes.EVENTS.COOKIE_UPDATED, cookieUpdatedCallback);
	}, [setCookie]);
}
