import * as analyticsActions from '../analytics.actions';
import * as paymentMethodActions from '../paymentMethod.actions';
import { SHIPPING_METHOD_ID_TO_ANALYTICS_VALUE_MAP } from '../../const/shippingMethod.const';

export const triggerShoppingCartEvent = (eventName, attrs = {}) => (dispatch) => {
	return dispatch(analyticsActions.triggerOrQueueEventRequiringCartId(eventName, attrs));
};

export const tagShoppingCartLoad = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShoppingCart_Load'));
};

export const tagShoppingCartEditItem = (groupId) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShoppingCart_EditItem', { chatgroupid: groupId }));
};

export const tagShoppingCartChangeQuantity = (groupId, quantity) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShoppingCart_ChangeQuantity', { chatgroupid: groupId, metric1: quantity }));
};

export const tagShoppingCartRemove = (groupId) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShoppingCart_Remove', { chatgroupid: groupId }));
};

export const tagShoppingCartCheckout = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShoppingCart_Checkout'));
};

export const tagShoppingCartGiftNote = (giftNoteEnabled) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShoppingCart_GiftNote', { attr1: giftNoteEnabled }));
};

export const tagShoppingCartPromo = (promoEnabled, code) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShoppingCart_Promo', {
		attr1: promoEnabled ? 'add' : 'remove',
		attr2: code,
	}));
};

export const tagShoppingCartKeepShopping = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShoppingCart_KeepShopping'));
};


export const tagCheckoutShippingAddressLoad = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingAddress_Load'));
};

export const tagCheckoutShippingAddressChangeCountry = (country) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingAddress_ChangeCountry', { attr1: country }));
};

export const tagCheckoutShippingAddressEditExisting = (addressId, result) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingAddress_EditExisting', { attr1: addressId, attr2: result }));
};

export const tagCheckoutShippingAddressAddNewAddress = (addressId) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingAddress_AddNewAddress', { attr1: addressId }));
};

export const tagCheckoutShippingAddressChooseAddress = (addressId) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingAddress_ChooseAddress', { attr1: addressId }));
};

export const tagCheckoutShippingAddressContinue = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingAddress_Continue'));
};


export const tagCheckoutShippingMethodLoad = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingMethod_Load'));
};

export const tagSelectionEdit = (eventName, selectionEditing) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent(eventName, { attr1: selectionEditing }));
};

export const tagBreadcrumbClick = (eventName, breadcrumbName) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent(eventName, { attr1: breadcrumbName }));
};

export const tagCheckoutShippingMethodChooseMethod = (shippingMethod) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingMethod_ChooseMethod', { attr1: SHIPPING_METHOD_ID_TO_ANALYTICS_VALUE_MAP[shippingMethod] }));
};

export const tagCheckoutShippingMethodContinue = (shippingMethod) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingMethod_Continue', { attr1: SHIPPING_METHOD_ID_TO_ANALYTICS_VALUE_MAP[shippingMethod] }));
};


export const tagCheckoutPaymentMethodLoad = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutPaymentMethod_Load'));
};

const tagPaymentMethodChooseMethod = (paymentMethodSelection, eventName) => (dispatch) => {
	const attrs = dispatch(paymentMethodActions.buildAttributesForPaymentMethodSelectionEvent(paymentMethodSelection));

	return dispatch(triggerShoppingCartEvent(eventName, attrs));
};

export const tagCheckoutPaymentMethodChooseMethod = (paymentMethodSelection) => (dispatch) => {
	return dispatch(tagPaymentMethodChooseMethod(paymentMethodSelection, 'CheckoutPaymentMethod_ChooseMethod'));
};


export const tagOrderReviewLoad = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('OrderReview_Load'));
};

export const tagOrderReviewPlaceOrder = (masterOrderId) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('OrderReview_PlaceOrder', { attr1: masterOrderId }));
};

export const tagOrderReviewCart = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('OrderReview_Cart'));
};


export const tagShippingAddressOverlayDone = (addressId) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingAddressOverlay_Done', { attr1: addressId }));
};

export const tagShippingAddressOverlayChooseAddress = (addressId) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingAddressOverlay_ChooseAddress', { attr1: addressId }));
};

export const tagShippingAddressOverlayAddNewAddress = (addressId) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingAddressOverlay_AddNewAddress', { attr1: addressId }));
};

export const tagShippingAddressOverlayEditAddress = (addressId, result) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingAddressOverlay_EditAddress', { attr1: addressId, attr2: result }));
};

export const tagShippingAddressOverlayCancel = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingAddressOverlay_Cancel'));
};


export const tagShippingMethodOverlayDone = (shippingMethod) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingMethodOverlay_Done', { attr1: SHIPPING_METHOD_ID_TO_ANALYTICS_VALUE_MAP[shippingMethod] }));
};

export const tagShippingMethodOverlayChooseMethod = (shippingMethod) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingMethodOverlay_ChooseMethod', { attr1: SHIPPING_METHOD_ID_TO_ANALYTICS_VALUE_MAP[shippingMethod] }));
};

export const tagShippingMethodOverlayCancel = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingMethodOverlay_Cancel'));
};


export const tagPaymentMethodOverlayChooseMethod = (paymentMethodSelection) => (dispatch) => {
	return dispatch(tagPaymentMethodChooseMethod(paymentMethodSelection, 'PaymentMethodOverlay_ChooseMethod'));
};
