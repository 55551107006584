import { appStringModel } from '../model/appString.model';

export const CHECKOUT_STEPS = {
	CART: 'cart',
	SHIPPING_ADDRESS: 'shippingAddress',
	SHIPPING_METHOD: 'shippingMethod',
	PAYMENT_METHOD: 'paymentMethod',
	REVIEW: 'review',
	ORDER_CONFIRMATION: 'orderConfirmation',
};

export const SELECTION_CHECKOUT_STEPS = new Set([
	CHECKOUT_STEPS.SHIPPING_ADDRESS,
	CHECKOUT_STEPS.SHIPPING_METHOD,
	CHECKOUT_STEPS.PAYMENT_METHOD,
]);

export const CHECKOUT_SHIPPING_STEPS = new Set([
	CHECKOUT_STEPS.SHIPPING_ADDRESS,
	CHECKOUT_STEPS.SHIPPING_METHOD,
]);

export const BREADCRUMBABLE_CHECKOUT_STEPS = new Set([
	CHECKOUT_STEPS.CART,
	CHECKOUT_STEPS.SHIPPING_ADDRESS,
	CHECKOUT_STEPS.SHIPPING_METHOD,
	CHECKOUT_STEPS.PAYMENT_METHOD,
	CHECKOUT_STEPS.REVIEW,
]);

export const CHECKOUT_STEP_LOCATION_MAP = {
	[CHECKOUT_STEPS.CART]: '/cart',
	[CHECKOUT_STEPS.SHIPPING_ADDRESS]: `/checkout/${CHECKOUT_STEPS.SHIPPING_ADDRESS}`,
	[CHECKOUT_STEPS.SHIPPING_METHOD]: `/checkout/${CHECKOUT_STEPS.SHIPPING_METHOD}`,
	[CHECKOUT_STEPS.PAYMENT_METHOD]: `/checkout/${CHECKOUT_STEPS.PAYMENT_METHOD}`,
	[CHECKOUT_STEPS.REVIEW]: `/checkout/${CHECKOUT_STEPS.REVIEW}`,
	[CHECKOUT_STEPS.ORDER_CONFIRMATION]: `/checkout/${CHECKOUT_STEPS.ORDER_CONFIRMATION}`,
};

export const CHECKOUT_STEP_APP_STRING_MAP = {
	[CHECKOUT_STEPS.CART]: appStringModel({ id: 'web.checkout.breadcrumb.cart' }),
	[CHECKOUT_STEPS.SHIPPING_ADDRESS]: appStringModel({ id: 'web.checkout.breadcrumb.address' }),
	[CHECKOUT_STEPS.SHIPPING_METHOD]: appStringModel({ id: 'web.checkout.breadcrumb.shipping' }),
	[CHECKOUT_STEPS.PAYMENT_METHOD]: appStringModel({ id: 'web.checkout.breadcrumb.payment' }),
	[CHECKOUT_STEPS.REVIEW]: appStringModel({ id: 'web.checkout.breadcrumb.review' }),
};

export const CART_CHECKOUT_STEPS = [
	CHECKOUT_STEPS.CART,
	CHECKOUT_STEPS.SHIPPING_ADDRESS,
	CHECKOUT_STEPS.SHIPPING_METHOD,
	CHECKOUT_STEPS.PAYMENT_METHOD,
	CHECKOUT_STEPS.REVIEW,
	CHECKOUT_STEPS.ORDER_CONFIRMATION,
];

export const CART_CHECKOUT_CONTINUE_TEXT_MAP = {
	[CHECKOUT_STEPS.SHIPPING_METHOD]: appStringModel({ id: 'web.checkout.continueToShippingMethod' }),
	[CHECKOUT_STEPS.PAYMENT_METHOD]: appStringModel({ id: 'web.checkout.continueToPaymentMethod' }),
	[CHECKOUT_STEPS.REVIEW]: appStringModel({ id: 'web.checkout.continueToReview' }),
	[CHECKOUT_STEPS.ORDER_CONFIRMATION]: appStringModel({ id: 'web.checkout.placeOrder' }),
};

export const CHECKOUT_STEP_SELECTION_EDIT_ANALYTICS_MAP = {
	[CHECKOUT_STEPS.SHIPPING_METHOD]: 'CheckoutShippingMethod_Edit',
	[CHECKOUT_STEPS.PAYMENT_METHOD]: 'CheckoutPaymentMethod_Edit',
	[CHECKOUT_STEPS.REVIEW]: 'OrderReview_ChangeOption',
};

export const CHECKOUT_STEP_BREADCRUMB_CLICK_ANALYTICS_MAP = {
	[CHECKOUT_STEPS.SHIPPING_ADDRESS]: 'CheckoutShippingAddress_Breadcrumb',
	[CHECKOUT_STEPS.SHIPPING_METHOD]: 'CheckoutShippingMethod_Breadcrumb',
	[CHECKOUT_STEPS.PAYMENT_METHOD]: 'CheckoutPaymentMethod_Breadcrumb',
	[CHECKOUT_STEPS.REVIEW]: 'OrderReview_Breadcrumb',
};
