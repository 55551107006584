import { makeSherpaLinkResolver } from './makeSherpaLinkResolver';
import { HAPPYKIT_FLAG_KEYS } from '../const';

export function sherpa({
	infiniteOrigin,
	makeItRainOrigin,
	site,
	flags,
}) {

	const currentSite = { // Site
		name: site,
		origin: typeof window === 'undefined' ? '' : window && window.location && window.location.origin,
	};

	const makeItRain = { // Site
		name: 'makeItRain',
		origin: makeItRainOrigin,
	};

	const webInfinite = { // Site
		name: 'webInfinite',
		origin: infiniteOrigin,
	};

	const products = makeSherpaLinkResolver('/products', makeItRain, currentSite);

	return { // Sherpa
		account: {
			orders: makeSherpaLinkResolver('/account/orders', makeItRain, currentSite),
			profile: makeSherpaLinkResolver('/account/profile', makeItRain, currentSite)
		},
		cart: makeSherpaLinkResolver('/cart', flags[HAPPYKIT_FLAG_KEYS.REDIRECT_TO_NEW_CART] ? makeItRain : webInfinite, currentSite),
		create: {
			book: makeSherpaLinkResolver('/app/create?page=web_custom_explainer', webInfinite, currentSite),
			layflat: makeSherpaLinkResolver('/app/create?page=web_layflat_explainer', webInfinite, currentSite),
			/**
			 * Prints are no longer a supported product. So we'll route the user back to the products page.
			 * This is where it used to go link to. makeSherpaLinkResolver('/app/extras?actionUri=chatbooks:%2F%2Fcreateexplainer%3Fproduct%3D6&modal=product_explainer_custom_prints', webInfinite, currentSite),
			 */
			prints: products,
			series: makeSherpaLinkResolver('/app/create?page=web_series_explainer', webInfinite, currentSite),
			giftCard: flags[HAPPYKIT_FLAG_KEYS.NX_APP_NEW_GIFTCARD_PAGE]
				? makeSherpaLinkResolver('/new/giftcard', makeItRain, currentSite)
				: makeSherpaLinkResolver('/create/gift-card', webInfinite, currentSite),
		},
		forgotPassword: makeSherpaLinkResolver('/forgot-password', makeItRain, currentSite),
		groupsHome: flags[HAPPYKIT_FLAG_KEYS.NX_APP_HOME_PAGE]
			? makeSherpaLinkResolver('/groups', makeItRain, currentSite)
			: makeSherpaLinkResolver('/app/groups', webInfinite, currentSite),
		landing: {
			cards: makeSherpaLinkResolver('/photo-cards', webInfinite, currentSite),
			galleryWallTiles: makeSherpaLinkResolver('/gallery-wall-tiles', makeItRain, currentSite),
			monthlyMinis: makeSherpaLinkResolver('/monthly-minis', makeItRain, currentSite),
			products,
		},
		login: makeSherpaLinkResolver('/login', makeItRain, currentSite),
		marketingHome: makeSherpaLinkResolver('/', makeItRain, currentSite),
		privacyPolicy: makeSherpaLinkResolver('/privacy-policy', makeItRain, currentSite),
		resetPassword: makeSherpaLinkResolver('/resetpassword', makeItRain, currentSite),
		resetPasswordLegacy: makeSherpaLinkResolver('/resetpassword/', webInfinite, currentSite),
		signup: makeSherpaLinkResolver('/signup', makeItRain, currentSite),
		termsOfService: makeSherpaLinkResolver('/terms-of-service', makeItRain, currentSite),
		webInfinite: {
			loggingOut: makeSherpaLinkResolver('/loggingOut', webInfinite, currentSite),
		},
	};
}
