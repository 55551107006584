import { init, track, identify, Identify, setUserId, setOptOut, getDeviceId } from '@amplitude/analytics-browser';

let service;

async function initialize(apiKey, deviceId) {
  service = deviceId
    ? await init(apiKey, null, { deviceId }).promise
    : await init(apiKey, null).promise;

  return service;
}

function trackEvent(eventName, eventProperties) {
  track(eventName, eventProperties);
}

function setIdentity(user) {
  if (user) {
    // To match iOS, we use the string ID intentionally for the userId
    setUserId(user.Id);

    const identity = new Identify();
    identity.set('customer_id', user.Id);
    identity.set('personid', user.LongId.toString());
    identity.set('personidstring', user.Id);
    if (user.Email) {
      identity.set('email', user.Email);
    }
    if (user.FirstName) {
      identity.set('firstname', user.FirstName);
    }
    if (user.LastName) {
      identity.set('lastname', user.LastName);
    }
    identify(identity);
  } else {
    console.error('User is not defined');
  }
}

function optOutUser() {
  setOptOut(true);
}

function optInUser() {
  setOptOut(false);
}

export const Amplitude = {
  initialize,
  getDeviceId,
  optInUser,
  optOutUser,
  setIdentity,
  trackEvent,
};
