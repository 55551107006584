import { getCookieExpiration } from '../helpers/cookies';

const CHATBOOKS_DOMAIN = 'chatbooks.com';

export function defaultCookieOptions() {

	/**
	 * Note: If these values change, we need to validate that the site
	 * does not freeze up on angular pages.
	 *
	 * If `react-cookie` has two cookies with the same name, but from different
	 * domains, it can have trouble dealing with both of them and cause the site
	 * to freeze up.
	 */
	return {
		domain: getAppropriateCookieDomain(),
		expires: getCookieExpiration(),
		path: '/',
		secure: shouldBeSecure(),
	};
}

function getAppropriateCookieDomain() {

	const domainsThatWeDoNotCareIfSubdomainsWork = [
		'localhost',
	];

	const hostname = getCurrentHostname();

	return domainsThatWeDoNotCareIfSubdomainsWork
		.some((domainWeMightCareAbout) => hostname.endsWith(domainWeMightCareAbout))
		? undefined
		: CHATBOOKS_DOMAIN;
}

function shouldBeSecure() {

	const domainsThatWeDoNotCareAboutSecurity = [
		'localhost',
		'local.chatbooks.com',
	];

	const hostname = getCurrentHostname();

	return !domainsThatWeDoNotCareAboutSecurity
		.some((domainWeMightCareAbout) => hostname.endsWith(domainWeMightCareAbout))
}

function getCurrentHostname() {
	if (window && window.location && window.location.hostname) {
		return window.location.hostname;
	} else {
		return '';
	}
}
