/*
	Blueshift
	Blueshift offers the complete toolkit to transform your data into meaningful engagement that drives ROI.
	https://blueshift.com/

	Setup in vendors/blueshift.js
*/

const isActive = () => window.hasOwnProperty('blueshift');

const track = (name, attributes) => isActive() && window.blueshift.track(name, attributes);

const setIdentity = (user) =>
	isActive() &&
	window.blueshift.identify({
		customer_id: user.ID,
		email: user.Email,
		firstname: user.firstName,
		lastname: user.lastName
	});

export default {
	setIdentity,
	track
};
