import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as analyticsActions from '../../actions/analytics.actions';
import * as helpshiftActions from '../../actions/helpshift.actions';
import { ConfigurableLink } from '../../chatponents';
import Events from '../../helpers/analytics/Events';
import './Link.css';

const Link = ({
	analyticsActions,
	helpshiftActions,
	history,
	isFailure,
	isSuccess,
}) => {
	if (isFailure) {
		return (
			<ConfigurableLink
				className="learn-more-link"
				analyticEvent={() => {
					analyticsActions.clickTracking(Events.QuickReauthStatus_Action, { attr1: 'contactSupport' });
					helpshiftActions.openHelp();
				}}
			>
				<span className="underline">Contact Customer Support</span>
			</ConfigurableLink>
		)
	}

	if (isSuccess) {
		return (
			<ConfigurableLink
				className="learn-more-link"
				path="https://chatbooks.app.link/home"
				analyticEvent={() => analyticsActions.clickTracking(Events.QuickReauthStatus_Action, { attr1: 'seeBooks' })}
			>
				<span className="underline">See my books in the Chatbooks app</span>
			</ConfigurableLink>
		);
	}

	return (
		<ConfigurableLink
			className="learn-more-link"
			path="/ig-quick-flow/landing"
			linkCallback={(path) => history.push(path)}
			analyticEvent={() => analyticsActions.clickTracking(Events.QuickReauthLanding_Action, { attr1: 'learnMore' })}
		>
			Don't have an Instagram Book Series?<br />
			<span className="underline">Tap here to learn more.</span>
		</ConfigurableLink>
	);
};

Link.displayName = 'Link';

Link.propTypes = {
	analyticsActions: PropTypes.object,
	helpshiftActions: PropTypes.object,
	history: PropTypes.object,
	isFailure: PropTypes.bool,
	isSuccess: PropTypes.bool,
};

Link.defaultProps = {
	isFailure: false,
	isSuccess: false,
};

const mapDispatchToProps = (dispatch) => ({
	analyticsActions: bindActionCreators(analyticsActions, dispatch),
	helpshiftActions: bindActionCreators(helpshiftActions, dispatch),
});

export default withRouter(
	connect(
		null,
		mapDispatchToProps,
	)(Link)
);
