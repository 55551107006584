import { getGroupProductAttributeAppStrings } from '../groups/groups';
import { BOOK_SIZES } from '../../const/bookSizes.const';
import { COVER_TYPES } from '../../const/coverType.const';
import { appStringModel } from '../../model/appString.model';
import { isVolumeUsingCoverBundle } from '../../model/coverBundle.model';

export const calculateSeriesVolumePrice = (volume, bookSize, coverType, coverBundle, seriesPricing) => {
	const sizeAndCoverUpgradePrice = (bookSize, coverType, seriesPricing) => {
		const { basePrice, hardcover6X6UpgradePrice, eightByEightUpgradePrice, hardcover8X8UpgradePrice } = seriesPricing;

		const pricesBySizeAndCoverType = {
			[BOOK_SIZES.SIX_BY_SIX]: {
				[COVER_TYPES.SOFT]: basePrice,
				[COVER_TYPES.HARD]: basePrice + hardcover6X6UpgradePrice,
			},
			[BOOK_SIZES.EIGHT_BY_EIGHT]: {
				[COVER_TYPES.SOFT]: basePrice + eightByEightUpgradePrice,
				[COVER_TYPES.HARD]: basePrice + eightByEightUpgradePrice + hardcover8X8UpgradePrice,
			},
		};

		if (!pricesBySizeAndCoverType.hasOwnProperty(bookSize) || !pricesBySizeAndCoverType[bookSize].hasOwnProperty(coverType)) {
			return 0;
		} else {
			return pricesBySizeAndCoverType[bookSize][coverType];
		}
	}

	const premiumCoverPrice = (volume, coverBundle = {}) => {
		const { surcharge } = coverBundle;

		return isVolumeUsingCoverBundle(coverBundle, volume) ? surcharge || 0 : 0;
	}

	return sizeAndCoverUpgradePrice(bookSize, coverType, seriesPricing) + premiumCoverPrice(volume, coverBundle);
};

export const getSeriesVolumeProductAttributeAppStrings = (group, volume, coverBundle) => {
	const attrs = getGroupProductAttributeAppStrings(group);

	return {
		...attrs,
		premium: isVolumeUsingCoverBundle(coverBundle, volume)
			? attrs.premium
			: appStringModel({ id: '' }),
	};
}