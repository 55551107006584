/*
	Helpshift
	Helpshift delivers scalable customer service in your mobile apps and mobile websites.
	https://developers.helpshift.com/

	Setup in vendors/helpshift.js
*/

import { assign } from 'lodash';

const isActive = () => window.hasOwnProperty('Helpshift');

const open = () => isActive() && window.Helpshift('open');

const setIdentity = (user) => {
	if (!isActive()) return;

	if (!window.helpshiftConfig) {
		return setTimeout(() => setIdentity(user), 100);
	}

	assign(window.helpshiftConfig, {
		clearAnonymousUserOnLogin: true,
		userName: user.FirstName + ' ' + user.LastName,
		userEmail: user.Email,
		userId: user.ID
	});

	window.Helpshift('setCustomIssueFields', {
		user_has_purchased: {
			type: 'singleline',
			value: user.Metadata ? (user.Metadata.HasPlacedOrder ? 'Yes' : 'No') : 'Unknown'
		}
	});

	window.Helpshift('updateHelpshiftConfig');
};

const setInitialUserMessage = (userMessage) => isActive() && window.Helpshift('setInitialUserMessage', userMessage);

export default {
	open,
	setIdentity,
	setInitialUserMessage
};
