import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { REDUCERS } from '../const/reducers.const';
import sortReferrals from '../helpers/sortReferrals';
import { isString } from '../helpers/strings/strings';

export const selectUserState = (state) => deep(state, `${REDUCERS.USER}`);

export const selectUser = createSelector(
	selectUserState,
	(userState) => deep(userState, 'user')
);

export const selectUserLongId = createSelector(
	selectUser,
	(user) => deep(user, 'LongId')
);

export const selectUserEmail = createSelector(
	selectUser,
	(user) => deep(user, 'Email')
);

export const selectUserIdString = createSelector(
	selectUser,
	(user) => deep(user, 'ID')
);

export const selectAccessTokenUnsetter = createSelector(
	selectUserState,
	(userState) => deep(userState, 'accessTokenUnsetter')
);

export const selectAccessToken = createSelector(
	selectUserState,
	(userState) => deep(userState, 'accessToken')
);

const selectAuthError = createSelector(
	selectUserState,
	(userState) => deep(userState, 'error')
);

export const selectAuthErrorMessage = createSelector(
	selectAuthError,
	(authError) => {
		if (!authError) return '';

		if (!authError.status) return 'Network Error';

		return (isString(authError)) ? authError : deep(authError, 'data.message') || 'Error';
	}
);

export const selectIsAuthenticating = createSelector(
	selectUserState,
	(userState) => deep(userState, 'authenticating')
);

export const selectIsLoginFailureDueToExistingUser = createSelector(
	selectUserState,
	(userState) => deep(userState, 'error.data.userExists')
);

export const selectIsLoggedIn = createSelector(
	selectAccessToken,
	(accessToken) => !!accessToken
);
export const selectIsRegisteredUser = createSelector(
	selectUserState,
	(userState) => deep(userState, 'isRegisteredUser') || false
);

export const selectIpAddress = createSelector(
	selectUserState,
	(userState) => deep(userState, 'ipAddress')
);

export const selectLoginModalModeIsPasswordReset = createSelector(
	selectUserState,
	(userState) => deep(userState, 'passwordReset')
);

export const selectUserCreditsBalance = createSelector(
	selectUser,
	(user) => deep(user, 'creditBalance') || 0
);

export const selectUserHasPlacedOrder = createSelector(
	selectUser,
	(user) => deep(user, 'Metadata.HasPlacedOrder')
);

export const selectUserReferrals = createSelector(
	selectUser,
	(user) => {
		const referrals = deep(user, 'referrals') || {};
		const Referrals = sortReferrals(deep(referrals, 'Referrals') || []);

		return {
			...referrals,
			Referrals
		};
	}
);

export const selectIsMobile = createSelector(
	selectUserState,
	(userState) => deep(userState, 'isMobile') || false
);

export const selectUserLanguage = createSelector(
	selectUserState,
	(userState) => deep(userState, 'lang')
);
