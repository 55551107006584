/**
 * Creates a lineItem object.
 *
 * @param {object} lineItem The lineItem object.
 * @param {string} lineItem.description The description
 * @param {bool} lineItem.isPriceVisible Whether a price should be displayed or not
 * @param {number} lineItem.price The price
 * @param {number} lineItem.quantity The quantity
 */
export const lineItemModel = ({
	description,
	isPriceVisible = true,
	price = 0,
	quantity = 0,
}) => ({
		description,
		isPriceVisible,
		price,
		quantity,
	});

