import deep from 'deep-get-set';
import { hermes } from '@chtbks/helpers';

import * as ACTIONS from './actionTypes';
import { setAnalyticsUser, clearAnalyticsUser } from './analytics.actions';
import * as api from './api/api.actions';
import { getAppStringFeatureFlags } from './appString.actions';
import { createOrRecalculateCart } from './checkout/checkout.actions';
import * as instagramLegacyActions from './instagramLegacy.actions';
import { openConfirmModal, modalOpened, openModalById } from './view.actions';
import { FORM_FIELDS } from '../const/formFields.const';
import { MODAL_NAMES } from '../const/modalNames.const';
import { SSO_TYPE } from '../const/sso.const';
import { queryStringFromJson, startsWithHttps } from '../helpers/locationUtils';
import { appStringModel } from '../model/appString.model';
import { confirmModalModel } from '../model/confirmModal.model';
import { modalModel } from '../model/modal.model';
import { logInModel } from '../model/logIn.model';
import { logInModalModel } from '../model/modal/loginModal.model';
import { newUserModel, withPersonId } from '../model/newUser.model';
import { ssoConnectionsFromJsonArray } from '../model/ssoConnection.model';
import { selectSherpa, selectMakeWebInfiniteLinkFn } from '../selectors';
import * as userState from '../selectors/user.selectors';
import * as AppleService from '../services/apple/apple';
import * as FacebookSsoService from '../services/facebook/facebookSso';

export const decrementThrobberCount = (source) => ({ type: ACTIONS.DECREMENT_THROBBER_COUNT, source });
export const errorAccountLogoff = (data) => ({ type: ACTIONS.ERROR_ACCOUNT_LOGOFF, data });
export const errorAccountLogon = (data) => ({ type: ACTIONS.ERROR_ACCOUNT_LOGON, data });
export const errorAccountCreate = (err) => ({ type: ACTIONS.ERROR_ACCOUNT_CREATE, data: err });
export const errorReferralInfo = (err) => ({ type: ACTIONS.ERROR_REFERRAL_INFO, data: err });
export const errorResetPassword = (data) => ({ type: ACTIONS.ERROR_RESET_PASSWORD, data });
export const errorRequestResetPassword = (data) => ({ type: ACTIONS.ERROR_REQUEST_RESET_PASSWORD, data });
export const errorUserLoggedin = (data) => ({ type: ACTIONS.ERROR_USER_LOGGEDIN, data });
export const errorTempuserLogon = (data) => ({ type: ACTIONS.ERROR_TEMPUSER_LOGON, data });
export const fetchAccountCreate = () => ({ type: ACTIONS.FETCH_ACCOUNT_CREATE });
export const fetchAccountLogoff = () => ({ type: ACTIONS.FETCH_ACCOUNT_LOGOFF });
export const fetchAccountLogon = () => ({ type: ACTIONS.FETCH_ACCOUNT_LOGON });
export const fetchReferralInfo = () => ({ type: ACTIONS.FETCH_REFERRAL_INFO });
export const fetchResetPassword = () => ({ type: ACTIONS.FETCH_RESET_PASSWORD });
export const fetchRequestResetPassword = () => ({ type: ACTIONS.FETCH_REQUEST_RESET_PASSWORD });
export const fetchTempuserLogon = () => ({ type: ACTIONS.FETCH_TEMPUSER_LOGON });
export const fetchUserLoggedin = () => ({ type: ACTIONS.FETCH_USER_LOGGEDIN });
export const incrementThrobberCount = (source) => ({ type: ACTIONS.INCREMENT_THROBBER_COUNT, source });
export const invalidateToken = () => ({ type: ACTIONS.INVALIDATE_TOKEN });
export const receiveAccountCreate = (data) => ({ type: ACTIONS.RECEIVE_ACCOUNT_CREATE, data });
export const receiveAccountLogoff = () => ({ type: ACTIONS.RECEIVE_ACCOUNT_LOGOFF });
export const receiveAccountLogon = (data) => ({ type: ACTIONS.RECEIVE_ACCOUNT_LOGON, data });
export const receivePasswordChanged = (accessToken) => ({ type: ACTIONS.RECEIVE_PASSWORD_CHANGED, accessToken });
export const receiveReferralInfo = (data) => ({ type: ACTIONS.RECEIVE_REFERRAL_INFO, data });
export const receiveRequestResetPassword = (data) => ({ type: ACTIONS.RECEIVE_REQUEST_RESET_PASSWORD, data });
export const receiveResetPassword = (data) => ({ type: ACTIONS.RECEIVE_RESET_PASSWORD, data });
export const receiveUserLoggedin = (data) => ({ type: ACTIONS.RECEIVE_USER_LOGGEDIN, data });
export const receiveTempuserLogon = (data) => ({ type: ACTIONS.RECEIVE_TEMPUSER_LOGON, data });
export const resetLoginState = () => ({ type: ACTIONS.RESET_LOGIN_STATE });
export const setAccessTokenUnsetter = (unsetter) => ({ type: ACTIONS.SET_ACCESS_TOKEN_UNSETTER, data: unsetter });

export const accountMerge = (sourceUserToken) => (dispatch) => {
	return dispatch(api.post_account_merge(sourceUserToken));
};

export const authenticateSso = (ssoType, isCreate) => (dispatch) => {
	const authPromise = ssoType === SSO_TYPE.APPLE ? dispatch(authenticateSso_apple(isCreate))
		: ssoType === SSO_TYPE.FACEBOOK ? dispatch(authenticateSso_facebook(isCreate))
			: Promise.reject();

	return authPromise
		.then(({ accessToken, email, name }) => dispatch(authenticateSso_handleAuthorized(accessToken, email, name, ssoType, isCreate)));
};
export const authenticateSso_apple = (isCreate) => (dispatch) => {
	return AppleService.loginForSso()
		.then(({ accessToken, email, name }) => dispatch(authenticateSso_handleAuthorized(accessToken, email, name, SSO_TYPE.APPLE, isCreate)));
};
export const authenticateSso_facebook = (isCreate) => (dispatch) => {
	return FacebookSsoService.authenticate()
		.then(({ accessToken, email, name }) => dispatch(authenticateSso_handleAuthorized(accessToken, email, name, SSO_TYPE.FACEBOOK, isCreate)));
};
export const authenticateSso_handleAuthorized = (accessToken, email, name, ssoType, isCreate = false) => (dispatch) => {
	const authPromise = isCreate
		? dispatch(createSsoUser(name, email, accessToken, ssoType))
		: dispatch(logInSsoUser(name, email, accessToken, ssoType));

	return authPromise.then(() => ({ accessToken, email, name, ssoType }));
};

export const checkForLoginParamAndMaybeGoToLogin = () => (dispatch) => {
	if (!window) return;

	if (window.location.search.includes('login')) {
		dispatch(openLoginPage());
	}
};

export const createEmailUser = (name, email, password) => (dispatch) => {
	const newUser = newUserModel({
		name,
		email,
		password,
	});

	return dispatch(createUser(newUser))
		.then((data) => {
			dispatch(setAnalyticsUser(deep(data, 'User')));
			dispatch(receiveAccountCreate(data));
		})
		.catch((err) => {
			const emailInUse = deep(err, 'data.emailInUse') || false;
			const existingUserSsoType = deep(err, 'data.ssoType') || SSO_TYPE.NONE;

			if (emailInUse && password) {
				return dispatch(logInEmailUser(email, password))
					.catch((err) => {
						return (existingUserSsoType !== SSO_TYPE.NONE)
							? dispatch(createEmailUser_handleNewUserExistingSsoUserConflict(email, existingUserSsoType))
							: Promise.reject(err);
					});
			}

			return Promise.reject(err);
		})
		.catch((err) => {
			dispatch(errorAccountCreate(err));

			return Promise.reject(err);
		});
};
const createEmailUser_handleNewUserExistingSsoUserConflict = (email, existingUserSsoType) => (dispatch) => {
	return dispatch(createEmailUser_openNewEmailUserExistingSsoUserConflictModal(email, existingUserSsoType))
		.then(({ userWantsToAddPassword = false } = {}) => {
			return dispatch(authenticateSso(existingUserSsoType, false))
				.then((authResult) => {
					return !userWantsToAddPassword
						? Promise.resolve()
						: dispatch(createEmailUser_openAddPasswordModal())
							.then((password) => {
								return dispatch(updateUserPassword({
									newPassword: password,
									singleSignOnToken: authResult.accessToken,
									singleSignOnType: existingUserSsoType,
								}));
							});
				});
		});
};
const createEmailUser_openNewEmailUserExistingSsoUserConflictModal = (email, ssoType) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(openModalById(modalModel({
			id: MODAL_NAMES.NEW_USER_EXISTING_SSO_USER_CONFLICT_MODAL,
			data: {
				email,
				ssoType,
				onContinueWithSso: resolve,
				onAddAChatbooksPassword: () => resolve({ userWantsToAddPassword: true }),
			},
		})))
			.catch(reject);
	});
};
const createEmailUser_openAddPasswordModal = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(openModalById(modalModel({
			id: MODAL_NAMES.ADD_PASSWORD_TO_SSO_USER_MODAL,
			data: {
				cancelCallback: () => reject,
				onPasswordSubmit: resolve,
			},
		})));
	});
};

const createSsoUser = (name, email, token, ssoTypeToCreate, { hasTriedSsoLogin = false } = {}) => (dispatch) => {
	const newUser = newUserModel({
		name,
		email,
		token,
		singleSignOnType: ssoTypeToCreate,
	});

	return dispatch(createUser(newUser))
		.then((data) => {
			dispatch(setAnalyticsUser(deep(data, 'User')));
			dispatch(receiveAccountCreate(data));
		})
		.catch((err) => {
			const emailInUse = deep(err, 'data.emailInUse') || false;
			const existingUserSsoType = deep(err, 'data.ssoType') || SSO_TYPE.NONE;

			if (emailInUse) {
				const maybeTryLoginPromise = emailInUse && ssoTypeToCreate === existingUserSsoType && !hasTriedSsoLogin
					? dispatch(logInSsoUser(name, email, token, ssoTypeToCreate))
					: Promise.reject();

				return maybeTryLoginPromise
					.catch(() => {
						return (existingUserSsoType === SSO_TYPE.NONE)
							? dispatch(createSsoUser_handleNewSsoUserExistingEmailUserConflict(email, token, ssoTypeToCreate, existingUserSsoType))
							: dispatch(createSsoUser_handleNewSsoUserExistingSsoUserConflict(email, ssoTypeToCreate, existingUserSsoType));
					});
			}

			return Promise.reject(err);
		});
};
const createSsoUser_handleNewSsoUserExistingEmailUserConflict = (email, accessToken, ssoType, existingUserSsoType) => (dispatch) => {
	return dispatch(createSsoUser_openNewSsoUserExistingUserConflictModal(email, ssoType))
		.then(() => {
			return dispatch(openLogInModal(logInModalModel({
				email,
				showSignUp: false,
				showSsoType: existingUserSsoType,
			})))
				.then(() => dispatch(createSsoUser_connectSsoToExistingUser(email, accessToken, ssoType)))
				.catch((err) => {
					console.error('Unable to connect SSO account to existing account.', err);

					return Promise.reject(err);
				});
		});
};
const createSsoUser_handleNewSsoUserExistingSsoUserConflict = (email, ssoTypeToCreate, existingUserSsoType) => (dispatch) => {
	return dispatch(createSsoUser_openNewUserExistingSsoUserConflictModal(email, ssoTypeToCreate, existingUserSsoType));
};
const createSsoUser_openNewUserExistingSsoUserConflictModal = (email, ssoTypeToCreate, existingUserSsoType) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(openModalById(modalModel({
			id: MODAL_NAMES.NEW_USER_EXISTING_SSO_USER_CONFLICT_MODAL,
			data: {
				email,
				ssoType: existingUserSsoType,
				ssoTypeToCreate,
				onContinueWithSso: resolve,
				onCancel: reject,
			},
		})))
			.catch(reject);
	});
};
const createSsoUser_openNewSsoUserExistingUserConflictModal = (email, ssoType) => (dispatch) => {
	return dispatch(openModalById(modalModel({
		id: MODAL_NAMES.NEW_SSO_USER_EXISTING_USER_CONFLICT_MODAL,
		data: {
			email,
			ssoType,
		},
	})));
};
const createSsoUser_connectSsoToExistingUser = (email, accessToken, ssoType) => (dispatch, getState) => {
	return dispatch(api.post_singleSignOn_connectNew(
		email,
		accessToken,
		ssoType,
		userState.selectUserLongId(getState())
	));
};

const createUser = (newUser) => (dispatch, getState) => {
	const isRegisteredUser = userState.selectIsRegisteredUser(getState());
	const user = userState.selectUser(getState());

	const newUserWithPersonId = user && user.ID && !isRegisteredUser
		? withPersonId(newUser, user.ID)
		: newUser;

	dispatch(incrementThrobberCount(createUser.name));
	dispatch(fetchAccountCreate());

	return dispatch(api.post_welcome_createUser(newUserWithPersonId))
		.finally(() => dispatch(decrementThrobberCount(createUser.name)));
};

export const deleteSsoConnection = (id) => (dispatch) => {
	return dispatch(api.delete_singleSignOn__id(id));
};

export const initializeUser = (suppressTempUser) => (dispatch, getState) => {
	const state = getState();
	const accessToken = userState.selectAccessToken(state);
	const user = userState.selectUser(state);

	if (accessToken) {
		dispatch(incrementThrobberCount(initializeUser.name));

		return dispatch(loadAccessTokenIsExpired())
			.then((isAccessTokenExpired) => {
				if (isAccessTokenExpired) {
					return !suppressTempUser ? dispatch(logInTempUser()) : dispatch(receiveAccountLogoff());
				}

				if (!user) {
					return Promise.all([
						dispatch(loadLoggedInUser()),
						dispatch(getAppStringFeatureFlags()),
					])
						// not loading cart here, loading in App, client side
						.catch((data) => dispatch(errorUserLoggedin(data)));
				}

				return Promise.resolve();
			})
			.finally(() => dispatch(decrementThrobberCount(initializeUser.name)));
	}

	if (!accessToken && !suppressTempUser) {
		return dispatch(logInTempUser());
	}
};

export const loadAccessTokenIsExpired = () => (dispatch) => {
	return dispatch(api.get_account_expiredToken())
		.then(({ tokenExpired }) => tokenExpired);
};

const loadLoggedInUser = () => (dispatch) => {
	dispatch(fetchUserLoggedin());

	return dispatch(api.get_user_loggedIn())
		.then((data) => {
			const loggedInUser = deep(data, 'User');
			dispatch(setAnalyticsUser(loggedInUser));
			dispatch(receiveUserLoggedin(data));
		});
};

export const loadReferralInfo = () => (dispatch) => {
	dispatch(incrementThrobberCount(loadReferralInfo.name));
	dispatch(fetchReferralInfo());

	return dispatch(api.get_referrals_info())
		.then((data) => dispatch(receiveReferralInfo(data)))
		.catch((data) => dispatch(errorReferralInfo(data)))
		.finally(() => dispatch(decrementThrobberCount(loadReferralInfo.name)));
};

export const loadSsoConnections = () => (dispatch) => {
	return dispatch(api.get_singleSignOn_getAll())
		.then((connections) => ssoConnectionsFromJsonArray(connections));
};

export const loadUserInfo = () => (dispatch) => {
	return dispatch(loadLoggedInUser())
		.then(() => Promise.all([
			dispatch(loadReferralInfo()),
		]))
		.catch((data) => dispatch(errorUserLoggedin(data)));
};

export const logInEmailUser = (username, password) => (dispatch) => {
	return dispatch(logInUser(logInModel({
		username,
		password,
	})));
};

export const logInTempUser = () => (dispatch) => {
	dispatch(incrementThrobberCount(logInTempUser.name));
	dispatch(fetchTempuserLogon());

	return dispatch(api.post_tempUser_create())
		.then(({ accessToken }) => dispatch(api.post_tempUser_logOn(accessToken)))
		.then((data) => {
			const user = deep(data, 'Person');
			dispatch(setAnalyticsUser(user));
			dispatch(receiveTempuserLogon(data));
		})
		.then(() => dispatch(getAppStringFeatureFlags()))
		.then(() => dispatch(createOrRecalculateCart()))
		.catch((data) => dispatch(errorTempuserLogon(data)))
		.finally(() => dispatch(decrementThrobberCount(logInTempUser.name)));
};

export const logInSsoUser = (username, email, accessToken, ssoType) => (dispatch) => {
	return dispatch(logInUser(logInModel({
		email,
		ssoType,
		username,
		ssoToken: accessToken,
	})))
		.catch(() => dispatch(createSsoUser(username, email, accessToken, ssoType, { hasTriedSsoLogin: true })));
};

export const logInTempUserIfNotLoggedIn = () => (dispatch, getState) => {
	const isLoggedIn = userState.selectIsLoggedIn(getState());

	return isLoggedIn ? Promise.resolve() : dispatch(logInTempUser());
};

const logInUser = (logInModel) => (dispatch) => {
	dispatch(incrementThrobberCount(logInUser.name));
	dispatch(fetchAccountLogon());

	return dispatch(logInUser_performLogin(logInModel))
		.then((data) => {
			const user = deep(data, 'Person');

			dispatch(setAnalyticsUser(user));
			dispatch(receiveAccountLogon(data));
			hermes.deliver('loggedIn', user);
		})
		.then(() => dispatch(getAppStringFeatureFlags()))
		.then(() => {
			dispatch(createOrRecalculateCart());
			// intentionally not returning the above promise so that user can move on while cart loads
		})
		.then(() => dispatch(instagramLegacyActions.maybeOpenReauthModal()))
		.catch((err) => {
			dispatch(errorAccountLogon(err));
			throw err;
		})
		.finally(() => dispatch(decrementThrobberCount(logInUser.name)));
};
const logInUser_performLogin = (logInModel) => (dispatch, getState) => {
	const loginModelWithTempToken = {
		...logInModel,
		tempUserToken: userState.selectAccessToken(getState()),
	};

	return loginModelWithTempToken.ssoType
		? dispatch(api.post_account_logOnSingleSignOn(loginModelWithTempToken))
		: dispatch(api.post_account_logOn(loginModelWithTempToken));
};

export const logout = () => (dispatch, getState) => {
	dispatch(incrementThrobberCount(logout.name));
	dispatch(fetchAccountLogoff());

	const state = getState();
	const accessTokenUnsetter = userState.selectAccessTokenUnsetter(state);
	const isRegisteredUser = userState.selectIsRegisteredUser(state);

	const authCall = isRegisteredUser ? dispatch(api.post_account_logOff()) : Promise.resolve();

	return authCall
		.then(() => {
			dispatch(receiveAccountLogoff());
			accessTokenUnsetter && accessTokenUnsetter();
			dispatch(clearAnalyticsUser());
		})
		.catch((data) => dispatch(errorAccountLogoff(data)))
		.finally(() => dispatch(decrementThrobberCount(logout.name)));
};

export const maybeOpenLogInModal = () => (dispatch, getState) => {
	return userState.selectIsRegisteredUser(getState())
		? Promise.resolve()
		: dispatch(openLogInModal());
};

export const maybeOpenLoginPage = (history, redirectUrl) => (dispatch, getState) => {
	const isLoggedIn = userState.selectIsRegisteredUser(getState());
	if (isLoggedIn) {
		history.push(redirectUrl);
	} else {
		dispatch(openLoginPage(redirectUrl));
	}
};

export const openChangeCurrencyPromptModal = () => (dispatch) => {
	return dispatch(
		modalOpened(
			modalModel({
				id: MODAL_NAMES.CHANGE_CURRENCY_PROMPT,
			})
		)
	);
};

export const openChangeCurrencyModal = () => (dispatch) => {
	return new Promise((resolve, reject) => {
		return dispatch(
			modalOpened(
				modalModel({
					id: MODAL_NAMES.CHANGE_CURRENCY,
					data: {
						onCancel: reject,
						onCurrencyChanged: resolve,
					},
				})
			)
		);
	});
};

export const openChangePasswordModal = () => (dispatch) => {
	return dispatch(
		modalOpened(
			modalModel({
				id: MODAL_NAMES.CHANGE_PASSWORD
			})
		)
	);
};

export const openForgotPasswordModal = (loginModal) => (dispatch) => {
	return dispatch(openLogInModal(logInModalModel({
		...loginModal,
		form: FORM_FIELDS.LOGIN_FORGOT_PASSWORD_FORM,
	})));
};

export const openLogInModal = (loginModal) => (dispatch) => {
	dispatch(resetLoginState());

	return new Promise((resolve, reject) => {
		return dispatch(modalOpened(modalModel({
			id: MODAL_NAMES.LOGIN_MODAL,
			data: logInModalModel({
				...loginModal,
				cancelCallback: deep(loginModal, 'cancelCallback') || reject,
				signedInCallback: deep(loginModal, 'signedInCallback') || resolve,
			}),
		})));
	});
};

export const openLoginPage = (redirectUrl) => (_, getState) => {
	const sherpa = selectSherpa(getState());

	if (!!redirectUrl) {
		const isExternalLink = startsWithHttps;
		const redirectBackToWebInfinite = selectMakeWebInfiniteLinkFn(getState())(redirectUrl);
		const redirect = isExternalLink(redirectUrl) ? redirectUrl : redirectBackToWebInfinite;
		window.location.href = sherpa.login(queryStringFromJson({ redirect }));
	} else {
		const maybeRedirectBackToLocal = window.location.href.includes('local.chatbooks.com')
			? queryStringFromJson({ redirect: sherpa.groupsHome('', { forceAbsolute: true, }) })
			: '';

		window.location.href = sherpa.login(maybeRedirectBackToLocal);
	}
};

export const openResetPasswordModal = (loginModal) => (dispatch) => {
	return dispatch(openLogInModal(logInModalModel({
		...loginModal,
		form: FORM_FIELDS.LOGIN_RESET_PASSWORD_FORM,
	})));
};

export const openUserExistsLoginPrompt = ({ email }) => (dispatch) => {
	return dispatch(openConfirmModal(confirmModalModel({
		title: appStringModel({ id: 'web.checkout.shippingAddress.accountExistsModal.title' }),
		body: appStringModel({ id: 'web.checkout.shippingAddress.accountExistsModal.body' }),
		cancelText: appStringModel({ id: 'web.checkout.shippingAddress.accountExistsModal.cancel' }),
		confirmText: appStringModel({ id: 'web.checkout.shippingAddress.accountExistsModal.confirm' }),
	})))
		.then(() => dispatch(openLogInModal(logInModalModel({ email }))))
		.finally(() => dispatch(resetLoginState()));
};

export const requestResetPasswordEmail = (email) => (dispatch) => {
	dispatch(incrementThrobberCount(requestResetPasswordEmail.name));
	dispatch(fetchRequestResetPassword());

	return dispatch(api.post_account_passwordReset(email))
		.then((data) => dispatch(receiveRequestResetPassword(data)))
		.catch((data) => dispatch(errorRequestResetPassword(data)))
		.finally(() => dispatch(decrementThrobberCount(requestResetPasswordEmail.name)));
};

export const resetPassword = (id, password) => (dispatch) => {
	dispatch(incrementThrobberCount(resetPassword.name));
	dispatch(fetchResetPassword());

	return dispatch(api.post_account_setPassword(id, password))
		.then((data) => dispatch(receiveResetPassword(data)))
		.catch((data) => dispatch(errorResetPassword(data)))
		.finally(() => dispatch(decrementThrobberCount(resetPassword.name)));
};

export const setUserLocation = (location) => (dispatch) => {
	return dispatch(api.put_reflections_my_location(location));
};

export const updateUserPassword = ({ currentPassword, newPassword, singleSignOnToken, singleSignOnType }) => (dispatch) => {
	return dispatch(api.post_account_changePassword({ currentPassword, newPassword, singleSignOnToken, singleSignOnType }))
		.then((data) => {
			if (!data.success) throw data;

			if (data.access_token) {
				dispatch(receivePasswordChanged(data.access_token));
			}

			return data;
		})
		.catch((err) => {
			dispatch(errorAccountLogon(err));

			throw err;
		});
};
