import config from '../../helpers/config';
import loadScript from '../../helpers/loadScript';

export const getFacebookSdk = () => {
	if (window.FB) return Promise.resolve(window.FB);

	return new Promise((resolve, reject) => {
		window.fbAsyncInit = () => {
			window.FB.init({
				appId:  config.get('facebook.appId'),
				cookie: true,
				xfbml: true,
				version: 'v9.0',
			});
		};

		return loadScript('https://connect.facebook.net/en_US/sdk.js')
			.then(() => {
				if (!window.FB) throw new Error('Facebook SDK did not load');

				resolve(window.FB);
			})
			.catch(reject);
	});
};

const _getLoginStatus = () => {
	return getFacebookSdk()
		.then((FB) => new Promise((resolve) => {
			FB.getLoginStatus((response) => resolve(response.status));
		}));
};

export const authenticate = (options) => {
	return getFacebookSdk()
		.then((FB) => {
			return new Promise((resolve, reject) => {
				FB.login((response) => {
					if (response.status === 'connected' && response.authResponse) {
						resolve(response.authResponse);
					} else {
						reject();
					}
				}, options);
			});
		});
};

export const get = (path, options = {}) => {
	return getFacebookSdk()
		.then((FB) => new Promise((resolve) => {
			FB.api(path, options, resolve);
		}));
};

export const logout = () => {
	return _getLoginStatus()
		.then((status) => {
			if (status === 'connected') {
				return getFacebookSdk()
					.then((FB) => FB.logout());
			}
		});
};
