import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { ENVIRONMENTS } from '../const/environments.const';
import { REDUCERS } from '../const/reducers.const';
import { startsWithHttps, convertToHttps, getEnvironmentFromUrl } from '../helpers/locationUtils';
import { toLowerCase } from '../helpers/strings/strings';

export const selectConfigState = (state) => deep(state, `${REDUCERS.CONFIG}`);

export const selectAnalyticsConfig = createSelector(
	selectConfigState,
	(config) => deep(config, 'analytics')
);

export const selectApiBaseUrl = createSelector(
	selectConfigState,
	(config) => deep(config, 'apiBaseUrl')
);

export const selectAngularUrl = createSelector(
	selectConfigState,
	(config) => deep(config, 'angularUrl')
);

export const selectSiteUrl = createSelector(
	selectConfigState,
	(config) => deep(config, 'siteUrl')
);

export const selectUseHttps = createSelector(
	selectConfigState,
	(config) => deep(config, 'useHttps')
);

export const selectEnvironmentConfig = createSelector(
	selectConfigState,
	(config) => deep(config, 'config')
);

export const selectLanguageConfig = createSelector(
	selectConfigState,
	(config) => deep(config, 'lang')
);

export const selectLocalesConfig = createSelector(
	selectConfigState,
	(config) => deep(config, 'locales')
);

export const selectInstagramGraphApiConfig = createSelector(
	selectConfigState,
	(config) => deep(config, 'instagramGraphApi')
);

export const selectPaypalConfig = createSelector(
	selectConfigState,
	(config) => deep(config, 'paypal')
);

export const selectBraintreeAuthorization = createSelector(
	selectPaypalConfig,
	(paypalConfig) => deep(paypalConfig, 'braintreeAuthorization')
);

export const selectDefaultLanguage = createSelector(
	selectLanguageConfig,
	(langConfig) => deep(langConfig, 'defaultLang')
);

export const selectDefaultLanguageNameSpace = createSelector(
	selectLanguageConfig,
	(langConfig) => deep(langConfig, 'defaultNamespace')
);

export const selectAmplitudeConfig = createSelector(
	selectAnalyticsConfig,
	(analyticsConfig) => deep(analyticsConfig, 'amplitude')
);

export const selectAwsConfig = createSelector(
	selectAnalyticsConfig,
	(analytics) => deep(analytics, 'aws')
);

export const selectBranchConfig = createSelector(
	selectAnalyticsConfig,
	(analytics) => deep(analytics, 'branch')
);

export const selectKochavaConfig = createSelector(
	selectAnalyticsConfig,
	(analytics) => deep(analytics, 'kochava')
);

export const selectIsAmplitudeEnabled = createSelector(
	selectAmplitudeConfig,
	(amplitudeConfig) => deep(amplitudeConfig, 'enabled')
);

export const selectAmplitudeApiKey = createSelector(
	selectAmplitudeConfig,
	(amplitudeConfig) => deep(amplitudeConfig, 'apiKey')
);

export const selectAwsAppId = createSelector(
	selectAwsConfig,
	(aws) => deep(aws, 'appId')
);

export const selectIsAwsEnabled = createSelector(
	selectAwsConfig,
	(aws) => deep(aws, 'enabled')
);

export const selectAwsPoolId = createSelector(
	selectAwsConfig,
	(aws) => deep(aws, 'poolId')
);

export const selectAwsRegion = createSelector(
	selectAwsConfig,
	(aws) => deep(aws, 'region')
);

export const selectIsBranchEnabled = createSelector(
	selectBranchConfig,
	(branch) => deep(branch, 'enabled') || false
);

export const selectBranchKey = createSelector(
	selectBranchConfig,
	(branch) => deep(branch, 'key')
);

export const selectIsKochavaEnabled = createSelector(
	selectKochavaConfig,
	(kochava) => deep(kochava, 'enabled') || false
);

export const selectIsProductionEnvironment = createSelector(
	selectEnvironmentConfig,
	(env) => toLowerCase(env) === toLowerCase(ENVIRONMENTS.PROD)
);

export const selectIsPointingToProductionEnvironment = createSelector(
	selectApiBaseUrl,
	(apiUrl) => /api-prod/i.test(apiUrl)
);

export const selectApiUrl = createSelector(
	selectApiBaseUrl,
	selectUseHttps,
	(apiBaseUrl, useHttps) => {
		if (!apiBaseUrl) {
			return '';
		}

		if (startsWithHttps(apiBaseUrl)) {
			if (useHttps) {
				return convertToHttps(apiBaseUrl);
			}

			return apiBaseUrl;
		}

		return `http${useHttps ? 's' : ''}://${apiBaseUrl}`;
	}
);

export const selectHappykitKey = createSelector(
	selectConfigState,
	(config) => deep(config, 'happykit.apiKey')
);

export const selectApiEnvironment = createSelector(
	selectApiBaseUrl,
	(apiBaseUrl) => getEnvironmentFromUrl(apiBaseUrl)
);
