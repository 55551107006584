import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { LOAD_PRICING_SUCCESS } from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export const selectPricingState = (state) => deep(state, `${REDUCERS.PRICING}`);

const selectLoadPricingStatus = createSelector(
	selectPricingState,
	(pricing) => deep(pricing, 'loadPricingStatus')
);

export const selectPricing = createSelector(
	selectPricingState,
	(pricingState) => deep(pricingState, 'pricing') || {}
);

export const selectIsPricingInitialized = createSelector(
	selectLoadPricingStatus,
	(status) => status === LOAD_PRICING_SUCCESS
);

export const selectSeriesPricing = createSelector(
	selectPricing,
	(pricing) => deep(pricing, 'series') || {}
);
