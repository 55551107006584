export const APP_STRING_KEYS = {
	APPLE_SSO_ENABLED: 'web.feature.apple_sso',
	BOOKS_TEN_BY_TEN_CREATE_ENABLED: 'web.feature.books.10x10.create',
	CHANGE_BONUS_PRINTS_ENABLED: 'web.feature.change_bonus_prints',
	FACEBOOK_SSO_ENABLED: 'web.feature.facebook_sso',
	FEATURES: 'web.feature',
	IG_QUICK_FLOW_BRANCH_ENABLED: 'web.feature.ig_quick_flow_branch_enabled',
	LAYFLAT_CREATE_ENABLED: 'web.feature.books.layflat.create',
	PDP_ENABLED: 'web.feature.product_details_page_enabled',
	PDP: 'web.cards.product-details-page',
	PHOTO_CARDS: 'web.photo-cards',
	PHOTO_SOURCE_ENABLED__COMPUTER: 'web.photosource_computer_enabled',
	PHOTO_SOURCE_ENABLED__FACEBOOK: 'web.photosource_facebook_enabled',
	PHOTO_SOURCE_ENABLED__GOOGLE: 'web.photosource_google_enabled',
	PHOTO_SOURCE_ENABLED__INSTAGRAM: 'web.photosource_instagram_enabled',
	PLP_QUERY_TEMPLATES_WITH_COMBINE_TEMPLATE_SETS_PARAM_ENABLED: 'web.feature.cards.query_templates_with_combineTemplateSets_param',
	PLP_STICKY_FILTERS: 'web.feature.product_landing_page_sticky_filters_enabled',
	PLP: 'web.cards.product-list-page',
	PRODUCTS: 'web.products',
};
