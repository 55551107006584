import deep from 'deep-get-set';
import { createSelector } from 'reselect';
import { objectMap } from '@chtbks/helpers';

import { selectAppStringOverrideMap, selectAppCookiesState } from './appCookie.selectors';
import { APP_STRING_DEFAULTS } from '../const/appStringDefaults.const';
import { APP_STRING_KEYS } from '../const/appStringKeys.const';
import { REDUCERS } from '../const/reducers.const';
import { getAppStringValueFromMap, isAppStringEnabled, getAppStringAndTranslateUndefinedIfDoesNotExist, asValue, isAppStringNamespaceInMap, getAppStringAndTranslate } from '../helpers/appStrings/appStrings';
import { objectValues } from '../helpers/objects/objects';

export const selectAppStringState = (state) => deep(state, `${REDUCERS.APP_STRING}`);

export const selectAppStringOverrides = createSelector(
	selectAppStringOverrideMap,
	(overrides) => objectMap(overrides, (override) => asValue(override))
);

export const selectIsAppStringTrackerEnabled = createSelector(
	selectAppCookiesState,
	(appStringState) => deep(appStringState, 'isAppStringTrackerEnabled') || false
);

export const selectAppStringsOnDisplayMap = createSelector(
	selectAppStringState,
	(appStringState) => deep(appStringState, 'appStringsOnDisplay') || {}
);

export const selectAppStringsOnDisplayList = createSelector(
	selectIsAppStringTrackerEnabled,
	selectAppStringsOnDisplayMap,
	(isTrackerEnabled, appStringsMap) => !isTrackerEnabled
		? []
		: objectValues(appStringsMap)
			.filter((appString) => !!appString.displayCount)
);

export const selectAppStringByKeyMap = createSelector(
	selectAppStringState,
	selectAppStringOverrides,
	(appStringState, overrides) => ({
		...APP_STRING_DEFAULTS,
		...deep(appStringState, 'stringByKeyMap'),
		...overrides,
	})
);

const selectLoadingAppStringNamespaces = createSelector(
	selectAppStringState,
	(appStringState) => deep(appStringState, 'loadingNamespaces') || {}
);

const selectLoadedAppStringNamespaces = createSelector(
	selectAppStringState,
	(appStringState) => deep(appStringState, 'loadedNamespaces') || {}
);

export const isAppStringNamespaceLoadedOrLoadingFunc = createSelector(
	selectLoadedAppStringNamespaces,
	selectLoadingAppStringNamespaces,
	(loadedNamespaces, loadingNamespaces) => (key) => isAppStringNamespaceInMap(loadedNamespaces, key) || isAppStringNamespaceInMap(loadingNamespaces, key)
);

export const selectAppStringDuploFunc = createSelector(
	selectAppStringByKeyMap,
	(appStringsByKey) => (key) => {
		const duploBlocks = getAppStringValueFromMap(appStringsByKey, key);

		if (!duploBlocks) return null;

		return typeof duploBlocks === 'object' ? duploBlocks : JSON.parse(duploBlocks);
	}
);

export const selectDuploProductCategoryTiles = createSelector(
	selectAppStringDuploFunc,
	(duploFunc) => duploFunc('web.products.tiles')
);

export const selectIsFeatureFlagEnabledFunc = createSelector(
	selectAppStringByKeyMap,
	(appStringsByKey) => (key) => isAppStringEnabled(getAppStringValueFromMap(appStringsByKey, key))
);

/**
 * Creates a function that takes an app string key and parameters object and provides the latest app string value.
 *
 * To ensure all app strings are fed through the app string tracker, only use this function in a component.
 * To translate a value eagerly, use the translate eagerly thunk action, otherwise, use one of the components.
 */
export const selectTranslateFunc = createSelector(
	selectAppStringByKeyMap,
	(appStringsByKey) => (key, params) => getAppStringAndTranslate(appStringsByKey, key, params)
);

export const selectTranslateUndefinedIfDoesntExist = createSelector(
	selectAppStringByKeyMap,
	(appStringsByKey) => (key, params) => getAppStringAndTranslateUndefinedIfDoesNotExist(appStringsByKey, key, params)
);

export const selectIsProductDetailsPageEnabled = createSelector(
	selectIsFeatureFlagEnabledFunc,
	(isFeatureFlagEnabledFunc) => isFeatureFlagEnabledFunc(APP_STRING_KEYS.PDP_ENABLED)
);

export const selectIsChangeBonusPrintsEnabled = createSelector(
	selectIsFeatureFlagEnabledFunc,
	(isFeatureFlagEnabledFunc) => isFeatureFlagEnabledFunc(APP_STRING_KEYS.CHANGE_BONUS_PRINTS_ENABLED)
);

export const selectIsAppleSsoEnabled = createSelector(
	selectIsFeatureFlagEnabledFunc,
	(isFeatureFlagEnabledFunc) => isFeatureFlagEnabledFunc(APP_STRING_KEYS.APPLE_SSO_ENABLED)
);

export const selectIsFacebookSsoEnabled = createSelector(
	selectIsFeatureFlagEnabledFunc,
	(isFeatureFlagEnabledFunc) => isFeatureFlagEnabledFunc(APP_STRING_KEYS.FACEBOOK_SSO_ENABLED)
);

export const selectIsFeatureFeatureFlagProductLandingPageStickyFiltersEnabled = createSelector(
	selectIsFeatureFlagEnabledFunc,
	(isFeatureFlagEnabledFunc) => isFeatureFlagEnabledFunc(APP_STRING_KEYS.PLP_STICKY_FILTERS)
);

export const selectDuploCardsCategoryTiles = createSelector(
	selectAppStringDuploFunc,
	(duploFunc) => duploFunc('web.photo-cards.tiles')
);

export const selectIsPlpQueryTemplatesWithCombineTemplateSetsParamEnabled = createSelector(
	selectIsFeatureFlagEnabledFunc,
	(isFeatureFlagEnabledFunc) => isFeatureFlagEnabledFunc(APP_STRING_KEYS.PLP_QUERY_TEMPLATES_WITH_COMBINE_TEMPLATE_SETS_PARAM_ENABLED)
);

export const selectIsBooksTenByTenCreateEnabled = createSelector(
	selectIsFeatureFlagEnabledFunc,
	(isFeatureFlagEnabledFunc) => isFeatureFlagEnabledFunc(APP_STRING_KEYS.BOOKS_TEN_BY_TEN_CREATE_ENABLED)
);

export const selectIsLayflatCreateEnabled = createSelector(
	selectIsFeatureFlagEnabledFunc,
	(isFeatureFlagEnabledFunc) => isFeatureFlagEnabledFunc(APP_STRING_KEYS.LAYFLAT_CREATE_ENABLED)
);

export const selectIsIgQuickFlowBranchEnabled = createSelector(
	selectIsFeatureFlagEnabledFunc,
	(isFeatureFlagEnabledFunc) => isFeatureFlagEnabledFunc(APP_STRING_KEYS.IG_QUICK_FLOW_BRANCH_ENABLED)
);

export const selectFeatureFlags = createSelector(
	selectIsFeatureFlagEnabledFunc,
	(isFeatureFlagEnabledFunc) => {
		const flags = [
			APP_STRING_KEYS.APPLE_SSO_ENABLED,
			APP_STRING_KEYS.GOOGLE_SSO_ENABLED,
			APP_STRING_KEYS.FACEBOOK_SSO_ENABLED,
			APP_STRING_KEYS.BOOKS_TEN_BY_TEN_CREATE_ENABLED,
			APP_STRING_KEYS.LAYFLAT_CREATE_ENABLED,
			APP_STRING_KEYS.CHANGE_BONUS_PRINTS_ENABLED,
			APP_STRING_KEYS.IG_QUICK_FLOW_BRANCH_ENABLED,
		];

		const dataSourceEnabledFlags = [
			APP_STRING_KEYS.PHOTO_SOURCE_ENABLED__COMPUTER,
			APP_STRING_KEYS.PHOTO_SOURCE_ENABLED__FACEBOOK,
			APP_STRING_KEYS.PHOTO_SOURCE_ENABLED__GOOGLE,
			APP_STRING_KEYS.PHOTO_SOURCE_ENABLED__INSTAGRAM,
		];

		return [
			...flags,
			...dataSourceEnabledFlags
		].map((key) => ({
			key,
			isEnabled: isFeatureFlagEnabledFunc(key),
		}));
	}
);

export const selectIsFeatureAppStringsInitialized = createSelector(
	selectAppStringState,
	(appStringState) => deep(appStringState, 'isFeatureAppStringInitialized') || false
);

export const selectShouldProductDetailsPageRedirect = createSelector(
	selectIsProductDetailsPageEnabled,
	selectIsFeatureAppStringsInitialized,
	(isProductDetailsPageEnabled, isFeatureAppStringsInitialized) => isFeatureAppStringsInitialized && !isProductDetailsPageEnabled
);
